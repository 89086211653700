import { Component, Inject, Input } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { EditorComponent } from 'ngx-monaco-editor-v2';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'ukata-code-editor-dialog',
  standalone: true,
  imports: [EditorComponent, FormsModule],
  templateUrl: './code-editor-dialog.component.html',
  styleUrl: './code-editor-dialog.component.scss',
})
export class CodeEditorDialogComponent {
  content: string = this.data.content;
  constructor(
    @Inject(NZ_MODAL_DATA)
    public data: {
      title: string;
      content: string;
      displayContentField?: boolean;
    },
  ) {}
  editorOptions = {
    theme: 'vs-dark',
    language: 'markdown',
  };
  getData() {
    return {
      content: this.content,
    };
  }
}
