<nz-form-item>
  <nz-form-label>Search term</nz-form-label>
  <input [(ngModel)]="searchTerm" nz-input placeholder="Search" type="text" />
</nz-form-item>
<button (click)="searchQuiz()" nz-button nzType="primary">Search</button>

<nz-table
  (nzQueryParams)="onQueryParamsChange($event)"
  [nzData]="quizzes"
  [nzFrontPagination]="false"
  [nzLoading]="loadingService.loading$ | async"
  [nzPageIndex]="pageIndex"
  [nzPageSizeOptions]="CommonService.pageSizeOptions"
  [nzPageSize]="pageSize"
  [nzTotal]="totalItems"
>
  <thead>
    <tr>
      <th>Select</th>
      <th>Title</th>
      <th>Details</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let quiz of quizzes">
      <td>
        <nz-radio-group [(ngModel)]="selectedQuiz" nzName="select-quiz">
          <label [nzValue]="quiz" nz-radio></label>
        </nz-radio-group>
      </td>
      <td>{{ quiz.title }}</td>
      <td>
        {{ quiz.durationInSeconds }} - {{ quiz.status }} - {{ quiz.quizAccess }}
      </td>
    </tr>
  </tbody>
</nz-table>
