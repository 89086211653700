import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'ukata-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit {
  @Input()
  options: string[] = CommonService.ItemLanguages;

  @Input()
  initialLanguage: string = 'ENGLISH';

  @Input()
  label: string = 'Language';

  selectedLanguage: string = '';

  @Output()
  onLanguageSelected = new EventEmitter<string>();

  ngOnInit() {
    if (this.initialLanguage) {
      this.selectedLanguage = this.initialLanguage;
    }
  }

  selected($event: any) {
    this.onLanguageSelected.emit($event);
  }
}
