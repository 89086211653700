import { Component, Input } from '@angular/core';

@Component({
  selector: 'ukata-loading-anim',
  templateUrl: './loading-anim.component.html',
  styleUrls: ['./loading-anim.component.scss'],
})
export class LoadingAnimComponent {
  @Input()
  bigBars: boolean = true;
}
