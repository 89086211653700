<div class="container">
  <div class="col">
    <h1>Terms of Service</h1>

    <p>Last updated: 31-Mar-2023</p>

    <p>
      Welcome to Ukata.io ("the App"), a web application that allows you to
      improve your English skills. These terms of service ("Terms") outline the
      agreement between you and the App's owner regarding your use of the App.
      By using the App, you agree to be bound by these Terms.
    </p>

    <h2>Data Collection and Use</h2>
    <p>
      The App uses Google Analytics, Facebook Login, and Google Login to collect
      data about your usage of the App. This data includes your IP address,
      browser type, operating system, device type, and other information that
      helps us improve the App's performance and user experience.
    </p>

    <p>
      By using the App, you consent to the collection and use of this data for
      the purposes of analytics and improving the App. We will never sell or
      rent your data to third parties.
    </p>
    <h2>Anti-Spam and API Abuse Policy</h2>
    <h3>Prohibited Conduct</h3>

    <p>
      Users must not use automated scripts, bots, or other unauthorized tools to
      access, use, or interact with our web app and APIs. Any activity intended
      to exploit, disrupt, or harm the integrity of our services, including but
      not limited to spamming, flooding, and overloading our systems, is
      strictly prohibited.
    </p>
    <h3>Detection and Prevention</h3>

    <p>
      We employ various techniques and technologies to detect and prevent
      abusive activities on our platform. This includes, but is not limited to,
      monitoring traffic patterns, rate limiting, and IP blacklisting.
    </p>
    <h3>Consequences of Violation</h3>

    <p>
      Users found engaging in prohibited conduct will have their accounts
      immediately suspended or terminated without notice. We reserve the right
      to take legal action against individuals or entities responsible for abuse
      and to seek damages to the fullest extent permitted by law.
    </p>
    <h3>No Refund Policy</h3>

    <p>
      Refunds will not be issued to users whose accounts are suspended or
      terminated due to violations of this Anti-Spam and API Abuse Policy. This
      includes but is not limited to any fees, subscriptions, or other payments
      made to our service.
    </p>
    <h3>Reporting Abuse</h3>

    <p>
      If you encounter any suspicious activity or believe your account has been
      compromised, please report it immediately to our support team at [support
      email/contact form].
    </p>
    <h3>Policy Changes</h3>

    <p>
      We reserve the right to modify this Anti-Spam and API Abuse Policy at any
      time. Changes will be effective immediately upon posting on our website.
    </p>
    <h2>Data Deletion</h2>
    <p>
      If you want to delete your data from the App, please contact us at
      dat.tm24&#64;gmail.com. We will delete your data as soon as possible, but
      please note that we may need to retain some information for legal or
      administrative purposes.
    </p>

    <h2>GDPR and Privacy Laws</h2>
    <p>
      We are committed to complying with GDPR and other privacy laws in the US
      and EU. This means that we will:
    </p>

    <ul>
      <li>Obtain your consent before collecting and using your data</li>
      <li>Only collect data that is necessary for the App's functionality</li>
      <li>Provide you with the ability to delete your data</li>
      <li>Protect your data from unauthorized access, disclosure, or use</li>
      <li>Notify you in the event of a data breach</li>
    </ul>

    <p>
      If you have any questions or concerns about our GDPR and privacy law
      compliance, please contact us at dat.tm24&#64;gmail.com.
    </p>

    <h2>Disclaimer of Warranties</h2>
    <p>
      The App is provided "as is" and we make no warranties or guarantees about
      its performance, availability, or suitability for any purpose. We are not
      responsible for any loss or damage that may result from your use of the
      App.
    </p>

    <h2>Limitation of Liability</h2>
    <p>
      In no event shall we be liable for any damages arising out of your use of
      the App, including but not limited to direct, indirect, incidental,
      consequential, or punitive damages.
    </p>

    <h2>Changes to these Terms</h2>
    <p>
      We reserve the right to modify these Terms at any time without prior
      notice. Your continued use of the App after any changes to these Terms
      constitutes your acceptance of the new Terms.
    </p>

    <h2>Rate limiting to users</h2>
    <p>
      As much as we want to, resources are not unlimited. Thus, we have to limit
      the number of requests to our server. We will try to make the limit as
      high as possible, but we cannot guarantee that it will be high enough for
      your needs. For now, all users, including free users and paid users, are
      limited to 1000 requests per day. Specifically, the request to speech
      assessments is limited to 100 requests per day per premium account. If you
      need more requests, please contact us using the contact page.
    </p>

    <h2>Governing Law and Jurisdiction</h2>
    <p>
      These Terms shall be governed by and construed in accordance with the laws
      of Vietnam. Any disputes arising out of or related to these Terms shall be
      resolved in the courts of Vietnam.
    </p>
  </div>
</div>
