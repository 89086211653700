import { Component, Inject, Input } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'ukata-html-content-editor-with-title-dialog',
  templateUrl: './html-content-editor-with-title-dialog.component.html',
  styleUrls: ['./html-content-editor-with-title-dialog.component.scss'],
})
export class HtmlContentEditorWithTitleDialogComponent {
  content: string = this.data.content;
  title: string = this.data.title;

  @Input()
  displayContentField: boolean = true;

  constructor(
    @Inject(NZ_MODAL_DATA)
    public data: {
      title: string;
      content: string;
      displayContentField?: boolean;
    },
  ) {}

  ngOnInit(): void {
    this.displayContentField = this.data.displayContentField ?? true;
  }

  getData() {
    return {
      title: this.title,
      content: this.content,
    };
  }
}
