import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ukata-media-type-picker',
  templateUrl: './media-type-picker.component.html',
  styleUrls: ['./media-type-picker.component.scss'],
})
export class MediaTypePickerComponent {
  @Output()
  onMediaTypeChange: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  mediaType: string = 'IMAGE';

  constructor() {}

  notify($event: string) {
    console.log('change to ', $event);
    this.onMediaTypeChange.emit($event);
  }
}
