import { AfterViewInit, Component, Input } from '@angular/core';
//@ts-ignore
import { v4 as uuid } from 'uuid';
import { SseService } from '../../../../core/services/sse.service';
import { PublicDictionaryService } from '../../../../core/services/public/public-dictionary.service';
import { MediaResponse } from '../../../interfaces/media-response';

interface TextToSpeechResponse {
  data: MediaResponse;
  requestId: string;
}

@Component({
  selector: 'ukata-ipa-to-speech',
  templateUrl: './ipa-to-speech.component.html',
  styleUrls: ['./ipa-to-speech.component.scss'],
})
export class IpaToSpeechComponent implements AfterViewInit {
  requestId: string = uuid();
  @Input()
  script: string = '';

  mediaResponse: MediaResponse | undefined;

  isLoading: boolean = false;

  private audio = new Audio();

  constructor(
    private publicDictionaryService: PublicDictionaryService,
    private sseService: SseService,
  ) {
    this.audio.oncanplaythrough = () => {
      this.isLoading = false;
      console.log('can play through');
      this.audio.play();
    };
  }

  ngAfterViewInit() {
    this.sseService.subscribeToSSE().subscribe({
      next: (res) => {
        if (res.topic.toLowerCase() === 'text_to_speech') {
          const ttsRes = res.data as TextToSpeechResponse;
          if (ttsRes.requestId !== this.requestId) {
            console.log(
              'request id mismatch, ignoring tts response',
              ttsRes.requestId,
              this.requestId,
            );
            return;
          }
          this.isLoading = false;
          this.mediaResponse = ttsRes.data;
          this.audio.src = this.mediaResponse.url;
        }
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  speak() {
    if (!this.script) {
      console.log('No script to speak');
      return;
    }
    this.isLoading = true;
    console.log('sending request', this.script, this.requestId);
    this.publicDictionaryService
      .fetchIPAPronunciation(this.script, this.requestId)
      .subscribe({
        next: (res) => {
          console.log('ipa request sent. waiting...', res);
        },
        error: (err) => {
          console.log(err);
          this.isLoading = false;
        },
      });
  }
}
