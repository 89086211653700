<nz-form-item>
  <nz-form-label>Languages</nz-form-label>
  <nz-select
    (ngModelChange)="selectedChanges($event)"
    [(ngModel)]="selectedLanguages"
    [nzShowSearch]="true"
    nzMode="multiple"
  >
    <nz-option
      *ngFor="let language of allLanguages"
      [nzLabel]="language | titlecase"
      [nzValue]="language"
    ></nz-option>
  </nz-select>
</nz-form-item>
