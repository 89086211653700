<div style="padding: 20px">
  <p *ngIf="showScript" class="speech-script">{{ script }}</p>
  <ukata-voice-recorder
    (onRecordingEventChange)="handleOnRecordingEventChange($event)"
    (onSpeechAssessmentResult)="handleSpeechAssessmentResult($event)"
    [parentRequestId]="requestId"
    [postProcessing]="'speech_analysis'"
    [script]="script"
    [showUserSpeechPlayer]="showUserAudio"
  ></ukata-voice-recorder>
  <!--  Display the assessment report here -->

  <div *ngIf="report">
    <div
      *ngIf="wordsEvaluation.length > 0"
      class="ukata-simple-box speech-assessment-words"
    >
      <span
        *ngFor="let word of wordsEvaluation"
        class="{{ word.cssClass }} speech-assessment-word"
        matTooltip="{{ word.score }}%"
      >
        {{ word.word }} &nbsp;
      </span>
    </div>
    <p></p>
    <div class="overall-report d-flex flex-row ukata-simple-box">
      <div class="single-score">
        <strong class="score-title">accuracy</strong>
        <span class="score-value">{{ report.overallAssessment.accuracy }}</span>
      </div>

      <div class="single-score">
        <strong class="score-title">fluency</strong>
        <span class="score-value">{{ report.overallAssessment.fluency }}</span>
      </div>

      <div class="single-score">
        <strong class="score-title">pronunciation</strong>
        <span class="score-value">{{
          report.overallAssessment.pronunciation
        }}</span>
      </div>
    </div>
    <p></p>
    <button (click)="viewDetails(report.documentId)" nz-button nzType="primary">
      Details
    </button>
    <mat-divider></mat-divider>
    <p></p>

    <nz-table [nzData]="report.wordAssessments">
      <thead>
        <tr>
          <th>Word</th>
          <th>Accuracy</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let word of report.wordAssessments">
          <td>{{ word.word }}</td>
          <td>{{ word.assessment.accuracy }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
