import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'ukata-visibility-picker',
  templateUrl: './visibility-picker.component.html',
  styleUrls: ['./visibility-picker.component.scss'],
})
export class VisibilityPickerComponent {
  @Input()
  visibility: string = 'PUBLIC';

  @Output()
  onVisibilityChange = new EventEmitter<string>();

  @Input()
  required: boolean = false;
  protected readonly CommonService = CommonService;

  notifyVisibilityChange($event: any) {
    this.onVisibilityChange.emit($event);
  }
}
