import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpeechAssessmentReport } from '../../../../shared/interfaces/assessment-result';

@Component({
  selector: 'ukata-speech-assessment-dialog',
  templateUrl: './speech-assessment-dialog.component.html',
  styleUrls: ['./speech-assessment-dialog.component.scss'],
})
export class SpeechAssessmentDialogComponent implements OnInit {
  script: string = '';
  report: SpeechAssessmentReport | undefined = undefined;
  showAdvancedOptions: boolean = false;
  hearMySpeech: boolean = false;
  listenToSample: boolean = false;
  showEditBox: boolean = false;
  showEditScriptIcon: boolean = false;
  showRefreshButton: boolean = true;
  @ViewChild('scriptInput') scriptInput:
    | ElementRef<HTMLInputElement>
    | undefined;
  private samples = [
    'She sells seashells by the seashore.',
    'How much wood would a woodchuck chuck if a woodchuck could chuck wood?',
    'Unique New York, unique New York.',
    'Red leather, yellow leather.',
    'Six sleek swans swam swiftly southward.',
    'I saw Susie sitting in a shoeshine shop.',
    'Toy boat, toy boat, toy boat.',
    'Annie and Andy ate apples and acorns.',
    'The quick brown fox jumps over the lazy dog.',
    'A big black bug bit a big black bear.',
    'Crazy lazy hazy days of summer.',
    'Sheep should sleep in a shed.',
    'Silly Sally swiftly shooed seven silly sheep.',
    'How can a clam cram in a clean cream can?',
    'I scream, you scream, we all scream for ice cream!',
    'Freshly fried flying fish.',
    "I won't wish the wish you wish to wish.",
    'If two witches were watching two watches, which witch would watch which watch?',
    'Black background, brown background.',
    'Seventy-seven benevolent elephants.',
    "The sixth sick Sheikh's sixth sheep's sick.",
    'Three free throws.',
    'Fred fed Ted bread, and Ted fed Fred bread.',
    'He threw three free throws.',
    'A proper copper coffee pot.',
    'Lesser leather never weathered wetter weather better.',
    'Unique New York, you need New York.',
    'Bobby brings bright blue balloons.',
    'Sally sells sea shells by the seashore, surely.',
    'Six slippery snails, slid slowly seaward.',
    'Fresh fish, fish fresh.',
    "She saw Sherif's shoes on the sofa.",
    'Rubber baby buggy bumpers.',
    'A proper cup of coffee from a proper copper coffee pot.',
    'Nine nimble noblemen nibbling on nuts.',
    'Two tiny tigers take two taxis to town.',
    'The great Greek grape growers grow great Greek grapes.',
    'Round the rugged rocks, the ragged rascals ran.',
    'Giggly giggling gophers gobbled gooseberries greedily.',
    'Red lorry, yellow lorry.',
    'Crisp crusts crackle and crunch.',
    "Luke's duck likes lakes. Luke's duck licks lakes.",
  ];

  constructor(
    public dialogRef: MatDialogRef<SpeechAssessmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      script: string;
      showRefreshButton: boolean;
      showEditScriptIcon: boolean;
    },
  ) {}

  ngOnInit() {
    if (!this.data.script) {
      this.rotateScript();
    } else {
      this.script = this.data.script;
    }
    this.showRefreshButton = this.data.showRefreshButton;
  }

  displayEditBox() {
    this.showEditBox = true;
    setTimeout(() => {
      this.scriptInput?.nativeElement.focus();
    }, 100);
  }

  rotateScript() {
    this.script = this.samples[Math.floor(Math.random() * this.samples.length)];
  }
}
