import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MakerMediaService } from '../../../core/services/maker-media.service';
import { MediaItem } from '../../../shared/interfaces/media-item';
import { NotificationService } from '../../../core/notifications/notification.service';

@Component({
  selector: 'ukata-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  files: Map<string, File> = new Map();
  @Input()
  acceptedFileTypes: string[] = ['audio', 'video', 'image'];

  isUploading: boolean = false;

  @Input()
  multiple: boolean = false;

  @Input()
  uploadOnSelected: boolean = false;

  @Input()
  showUploadButton: boolean = true; //in case upload on selected, button is not needed

  @Output()
  onFileUploaded: EventEmitter<MediaItem[]> = new EventEmitter<MediaItem[]>();

  constructor(
    private makerMediaService: MakerMediaService,
    public notificationService: NotificationService,
  ) {}

  ngOnInit(): void {}

  onFileSelected(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      if (!this.multiple) {
        console.log('clearing');
        this.files.clear();
      }
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i] as File;
        const mimeType = file.type;
        if (this.acceptedFileTypes.indexOf(mimeType.split('/')[0]) == -1) {
          this.notificationService.error('Unsupported media type');
          return;
        }
        this.files.set(file.name, file);
      }
    }

    //only allow upload on selected if not multiple
    if (this.uploadOnSelected && !this.multiple) {
      this.uploadFiles();
    }
  }

  uploadFiles() {
    if (this.files.size == 0) {
      return;
    }
    const formData = new FormData();
    for (let [k, file] of this.files) {
      formData.append('files[]', file);
    }
    this.isUploading = true;
    this.makerMediaService.uploadMultiple(formData).subscribe({
      next: (data) => {
        this.onFileUploaded.emit(data);
        this.isUploading = false;
        this.files.clear();
        this.notificationService.success('File uploaded');
      },
      error: (error) => {
        console.log('error uploading', error);
        this.notificationService.success('File upload failed');
        this.isUploading = false;
        this.files.clear();
      },
    });
  }
}
