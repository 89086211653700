<!-- Suitable for embedded view -->
<div class="vocabulary-list-items">
  <div class="row">
    <div
      *ngFor="let item of vocabularyListItems"
      class="col-md-4 col-12"
      style="margin-bottom: 15px"
    >
      <div class="ukata-simple-box single-vocabulary-item">
        <h3>{{ item.word }}</h3>
        <p class="item-ipa">{{ item.ipa }}</p>
        <div *ngIf="item.images && item.images.length > 0">
          <div *ngFor="let image of item.images">
            <ukata-media-item-image-view
              [mediaItemId]="image"
            ></ukata-media-item-image-view>
          </div>
        </div>
        <p>{{ item.shortDefinition }}</p>
        <ukata-media-item-view
          *ngIf="item.pronunciationMediaId"
          [mediaItemId]="item.pronunciationMediaId"
          [showScriptToggle]="false"
          [showScript]="false"
        ></ukata-media-item-view>

        <div class="example-sentences">
          <h4>Examples</h4>
          <ul class="ukata-ul-no-padding">
            <li
              *ngFor="let ex of item.examples.slice(0, 3)"
              class="single-example"
            >
              {{ ex }}
              <mat-icon
                (click)="openSpeechAssessmentDialog(ex)"
                nzType="primary"
                >hearing
              </mat-icon>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<nz-pagination
  (nzPageIndexChange)="fetchListItems(pageSize, $event)"
  (nzPageSizeChange)="fetchListItems($event, pageIndex)"
  *ngIf="displayPagination"
  [nzPageIndex]="pageIndex"
  [nzPageSizeOptions]="CommonService.pageSizeOptions"
  [nzPageSize]="pageSize"
  [nzTotal]="totalItems"
  aria-label="Select page"
>
</nz-pagination>
