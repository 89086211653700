import { Component, OnInit } from '@angular/core';
import { Subscription } from '../../interfaces/subscription';
import { CurrentUserService } from '../../../core/services/current-user.service';
import { LoadingService } from '../../../core/services/loading.service';
import { AuthGuard } from '../../../core/services/auth-guard.service';

@Component({
  selector: 'ukata-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  user = this.authService.userDetails();

  subscriptions: Subscription[] = [];

  constructor(
    public authService: AuthGuard,
    public loadingService: LoadingService,
    private currentUserService: CurrentUserService,
  ) {}

  ngOnInit(): void {
    this.currentUserService.fetchSubscriptions().subscribe(
      (subscriptions) => {
        this.subscriptions = subscriptions;
      },
      (error) => {
        console.log('error', error);
      },
    );
  }

  daysLeft(subscription: Subscription) {
    //calculate days left for subscription
    const endDate = new Date(subscription.endDate);
    const today = new Date();
    const timeDiff = endDate.getTime() - today.getTime();
    if (timeDiff > 0) {
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    } else {
      return 0;
    }
  }
}
