import { Injectable } from '@angular/core';

//@ts-ignore
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';
//@ts-ignore
import Plyr from 'plyr';
import {
  SpeechAssessmentReport,
  WordEvaluation,
} from '../../shared/interfaces/assessment-result';
import { MakerMediaService } from './maker-media.service';
import { MediaItem } from '../../shared/interfaces/media-item';
import { LoadingService } from './loading.service';
import { Definition } from '../../shared/interfaces/dictionary/definition';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MediaPickerDialogComponent } from '../../shared/components/media-picker-dialog/media-picker-dialog.component';
import { SingleInputModalComponent } from '../components/common-modals/common-modals.component';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public static MakerItemStatuses: string[] = ['DRAFT', 'PUBLISHED'];
  public static ItemVisibilities: string[] = ['PUBLIC', 'PRIVATE', 'UNLISTED'];
  public static MakerItemLevels: string[] = [
    'BEGINNER',
    'INTERMEDIATE',
    'ADVANCED',
  ];
  public static ItemLanguages: string[] = [
    'ENGLISH',
    'CHINESE',
    'HINDI',
    'SPANISH',
    'FRENCH',
    'ARABIC',
    'BENGALI',
    'RUSSIAN',
    'PORTUGUESE',
    'URDU',
    'INDONESIAN',
    'GERMAN',
    'JAPANESE',
    'SWAHILI',
    'TAMIL',
    'TURKISH',
    'KOREAN',
    'ITALIAN',
    'VIETNAMESE',
    'MARATHI',
    'TELUGU',
    'YORUBA',
    'UKRAINIAN',
    'IGBO',
    'MALAY',
    'DUTCH',
    'AMHARIC',
    'NEPALI',
    'ROMANIAN',
    'HAITIAN_CREOLE',
    'CEBUANO',
    'AZERBAIJANI',
    'HUNGARIAN',
    'GREEK',
    'ZULU',
    'CZECH',
    'KAZAKH',
    'TATAR',
    'HEBREW',
    'AFRIKAANS',
    'POLISH',
    'SUNDANESE',
    'JAVANESE',
    'SOUTHERN_MIN',
    'YUE_CHINESE',
    'CHITTAGONIAN',
    'GUJARATI',
    'NORWEGIAN',
    'BURMESE',
    'ORIYA',
    'TAGALOG',
    'BHOJPURI',
    'UKRANIAN',
    'HAKKA_CHINESE',
    'SERBO_CROATIAN',
    'UZBEK',
    'TURKMEN',
    'XHOSA',
    'SINDHI',
    'MAITHILI',
    'YIDDISH',
    'ALBANIAN',
    'AMERICAN_SIGN_LANGUAGE',
    'HAUSA',
    'TAIWANESE_HOKKIEN',
    'CEBUANO_BISAYA',
    'UYGHUR',
    'MOROCCAN_ARABIC',
    'ARABIC_ALGERIAN',
    'FULA',
    'ROMANSH',
    'KURDISH',
    'ROMANI',
    'AYMARA',
    'DHOLUO',
    'KICHE',
    'ASSAMESE',
    'QUECHUA',
    'TSWANA',
    'KINYARWANDA',
    'MALAGASY',
    'TAMAZIGHT',
    'RUKIGA',
    'SYLHETI',
    'FULA_NIGERIAN',
    'UKRAINIAN_SURZHYK',
    'OROMO',
    'PAPIAMENTO',
    'SERBIAN',
    'OTTOMAN_TURKISH',
    'PASHTO',
    'YORUBA_NIGERIA',
    'WOLOF',
    'KOMI',
    'SHONA',
    'CHICHEWA',
    'TETUM',
    'PUNJABI',
    'CHEWA',
    'NEPALI_INDIA',
    'ILOCANO',
    'LOZI',
    'GAGAUZ',
    'MIZO',
    'SWISS_GERMAN',
    'NORTHERN_SOHARIA',
    'KIRGHIZ',
    'GUARANI',
    'XHOSA_SOUTH_AFRICA',
    'SANGO',
    'EWE',
    'SAMOAN',
    'PAPIAMENTU',
    'PALAUAN',
    'KINYARWANDA_RWANDA',
    'HIRI_MOTU',
    'FAROESE',
    'KOYRA_CHIINI',
    'KPELLE',
    'HAWAIIAN',
    'KIRUNDI',
    'NAVAJO',
    'GANDA',
    'KABYLIAN',
    'KAONDE',
    'NORTHERN_KURDISH',
    'ILOKO',
    'KICONGO',
    'LOW_GERMAN',
    'FANG',
    'NORTHERN_SOTHO',
    'GARIFUNA',
    'SERBIAN_LATIN',
    'KUANUA',
    'OROMO_ETHIOPIA',
    'KARAKALPAK',
    'SHAN',
    'PONTIC_GREEK',
    'SANTALI',
    'ERZYA',
    'MINGRELIAN',
    'NDEBELE',
    'LUXEMBOURGISH',
    'GAGAUZ_MOLDAVIA',
    'OCCITAN',
    'BUGINESE',
    'ASTURIAN',
    'YUPIK',
    'VENDA',
    'CHEROKEE',
    'WOLOF_SENEGAL',
    'TIV',
    'SAMOAN_AMERICAN',
    'RAJASTHANI',
    'ISLAND_CARIB',
    'CHEYENNE',
    'MANX',
    'NORTHERN_MIRANDA',
    'MOKSHA',
    'MIEN',
    'FANG_GABON',
    'SENAYAN',
    'SANTALI_BANGLADESH',
    'NAMA',
    'RIFFIAN',
    'NYAMWEZI',
    'AINU',
    'SARAMACCAN',
    'LIGURIAN',
    'WAYUU',
    'KYRGYZ',
    'NAVAJO_USA',
    'LITHUANIAN',
    'BRAILLE',
    'SARAHIWI',
    'BLACKFOOT',
    'LEZGIAN',
    'ABKHAZIAN',
    'RENNELLESE',
    'NUKUORO',
    'BUGIS',
    'CHAMORRO',
    'SOTHO',
    'SOGDIAN',
    'SONGHAI',
    'VALLEY_YUPIK',
    'CHEROKEE_CHEROKEE',
    'ATIKAMEKW',
    'GANDA_UGANDA',
    'YUCATEC_MAYA',
    'INGUSH',
    'ARAPAHO',
    'KICHE_GUATEMALA',
    'CHEROKEE_CHEROKEE_NORTH_CAROLINA',
    'NAVAJO_NAVAJOLAND',
    'ROMANI_SERBIA',
    'GANDA_UGANDA_LUGANDA',
    'NAVAJO_NEW_MEXICO',
    'ISLAND_CARIB_CARIB',
    'YUCATEC_MAYA_BELIZE',
    'WOLOF_GAMBIA',
    'NAVAJO_UTAH',
    'NYAMWEZI_TANZANIA',
    'CHEROKEE_CHEROKEE_OKLAHOMA',
    'ISLAND_CARIB_KALINAGO',
    'KICHE_GUATEMALA_QUICHÉ',
    'YUCATEC_MAYA_MEXICO',
    'NAVAJO_NAVAJO_NATION',
    'CHEROKEE_CHEROKEE_NORTH_GEORGIA',
    'NAVAJO_ARIZONA',
    'NYAMWEZI_UGANDA',
    'ISLAND_CARIB_CARIB_DOMINICA',
    'YUCATEC_MAYA_MEXICO_QUINTANA_ROO',
    'CHEROKEE_CHEROKEE_WEST_GEORGIA',
    'NAVAJO_SOUTHERN_UTAH',
    'ISLAND_CARIB_CARIB_ST_VINCENT',
    'YUCATEC_MAYA_MEXICO_YUCATÁN',
    'CHEROKEE_CHEROKEE_EAST_GEORGIA',
    'ISLAND_CARIB_CARIB_ST_LUCIA',
    'CHEROKEE_CHEROKEE_WEST_GEORGIA_NORTH_CAROLINA',
    'CHEROKEE_CHEROKEE_WEST_GEORGIA_NORTH_CAROLINA_OKLAHOMA',
    'CHEROKEE_CHEROKEE_WEST_GEORGIA_OKLAHOMA',
    'CHEROKEE_CHEROKEE_WEST_GEORGIA_OKLAHOMA_NORTH_CAROLINA',
    'CHEROKEE_CHEROKEE_WEST_GEORGIA_NORTH_CAROLINA_SOUTH_CAROLINA',
    'CHEROKEE_CHEROKEE_WEST_GEORGIA_OKLAHOMA_NORTH_CAROLINA_SOUTH_CAROLINA',
  ];
  public static MakerCommonGrades: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public static pageSizeOptions: number[] = [5, 10, 20];
  public static adminPageSizeOptions: number[] = [10, 20, 50, 100];
  public static makerPageSizeOptions: number[] = [10, 20, 50, 100];
  public static MakerItemAccesses: string[] = [
    'PUBLIC', //can be accessed by anyone
    'PRIVATE', //only maker
    'MEMBER_PUBLIC', //only logged in member
    // 'REQUIRE_PAYMENT', //required payment
    // 'PASSWORD_PROTECTED', //password protected
  ];
  public static flagMap: Map<string, string> = new Map<string, string>([
    [
      'vi',
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAADsAAAA7AF5KHG9AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAYNJREFUWIXtlj1LA0EQhp/Zi2IIYiFBQfAPWPgRO1FUsJDrhKSy8mekV2y0tbMWtLAQtPEDC0XQylbRSkELC0ljvB2LszjkEm89uWvywsLCzu777MwsLHTUUc4SAF2sziNUEDGZuKpalBs53D0R9Wt10NVMjH9KtC7qV1+Aci4A8GpyNAcop6t5n4QjhdIBzBiYTndEut3jBip5ZWBIYBAYkHCeOcCEiZ87qpAoaiqm1uXIrWcNjPzIwrmFC/tPABcWuoElD7yY9SIw/A0QAEcBXP5unhxAgVMLjworHvS3qPkbsP0J95roWHDtgQeF9QAaMQYNhbWmk7k7AECPQikmAyWBovtrcAcYi2x5VniK3HjUHSBZD8QBXFnYCcACvgcLJlw7TtZ8fwPolfD5bQVwGzHaD+DOwnIhjHlP3geifjV5dBfQLfFNCGEffCg0E5/omIEm0GzD2wqsjQzw6rzrvyS8GJTN3ADQjfBT6tfmUJ3M7FMKAWKv5WDvLCO/jjpqrS/gD2OVcnNnvQAAAABJRU5ErkJggg==',
    ],
    [
      'en',
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAONSURBVFiF7ZVtTNVVHMc/5/zPfeCCdnm4F8mHoSaTp8BSgaJuVDQXIkRiNVerJRPHxlabW7ax9bBmG5usNNms5psWy5RqRNm0pJJMX4SADimHUgJFcOHKvRcul3tPL8yaL9za3wZv+Lz67bw4n9/5nt/OgXnmmWNEUdGezPgkZ5bWImpmg4rYP2KK1NCkKbuc6VQgz0S0cEgp0Vpf60qI/1wHohohtCk/2hiV0ah25K1bRumj6QCsXJFI5ePZACxKXkBNdQEADoeF2pp7/pG/WldsTnojicLjadBOlxMhBHW7HqZ+TxtToRl2VOVx6Eg3o94gzz+7nhPfXqTvkpfSknT6fx2jq/t3AEqWQ9lKaU4v5aQCuHvNYtyuOF7ffZy0VS7y1y+j8d3TuF1xbHtuLe8dPENsrJVdOx9kd/03CCF4aecDvFnfhspMx/5YttkEYozU1A2vjF0N03fJywu1hRz/+iIdnQPU1hTS1T1E+6l+ntqSw6g3SOvRHso3ZeFwWDjc3A3A6jQXGenJZhtAAaxbu5Rk9wLe2tfO8tQEcu9MYV9jO25XHNu35XPg/dPE2BU12wvYf+BHtIa6l4t57Y1jRH67Qvik35Rc2KwjwuNp0PHueAxD8mLtfezd304kqtn6ZC4trT1cnQixZXMOP5y6zJUBH0WeFQwOTdD785+EwxE22IcotQ2ZTkACZGclc39hKnsb21myxEnZxgyaDnUiDUllRTZHmrsYGfFTXZVP23d99FwYZkdVnmnpDSl4PA06KSUBZRg8vXUNHzSdRUrBE5tzaPniPMFgmOKHVvFTxwBj41PcW5DK4KCPvsteAoFpStIslGfGmJNbLF4FkJmxiMQEBx83d5PsjiV9tZtPPjuHzWZQXppFS+t5EFBRlkXzp+eYDkeprsrn7XdOYiy+HdtG02kkKIDe3mGsNsXERIhAYJpx3xQ+3xRSCr461suEfxohoPXLC/gD0wA0fdQBQNRvbgCvozZZBsYSZDCesAD736sh/q2DN6n91+o7fH5Cnx81JRcWNS5+eaRSO5amgDB5hFvE5Bv6/6FU1TOTtoULYwQmf7RbwW4bFv39g9rpvA0h5uYOJICeg8NfR0U+PDwTdDqVss7BOBgyoCInvldBKbEmxWPYrbPdQqxCCL+ORuNCw6MIZYSlIWdmy64RA0pGI3dFEbkAOjxDJDxbepDos7Nnm2eem/AXa8NBXofP0LgAAAAASUVORK5CYII=',
    ],
  ]);

  constructor() {}

  public static createPlyrAudioPlayer(playerId: string) {
    console.log('creating a new player ', playerId);
    const plyr = new Plyr(playerId);

    plyr.options.speed = {
      selected: 1,
      options: [0.75, 1, 1.25],
    };

    return plyr;
  }

  public static copyTextToClipboard(text?: string) {
    if (!text) {
      return;
    }
    navigator.clipboard && navigator.clipboard.writeText(text);
  }

  public static areTwoStringEquals(str1: string, str2: string): boolean {
    const sanitizeString = (str: string): string => {
      return str
        .trim()
        .toLowerCase()
        .replace(/[^a-z]/gi, '')
        .trim();
    };

    const sanitizedStr1 = sanitizeString(str1);
    const sanitizedStr2 = sanitizeString(str2);

    return sanitizedStr1 === sanitizedStr2;
  }

  public static showConfirmationDialog(
    modalService: NzModalService,
    title: string,
    content: string,
    callback: any,
  ) {
    modalService.confirm({
      nzTitle: title,
      nzContent: content,
      nzOnOk: () => callback(),
    });
  }

  public static showInputDialog(
    modalService: NzModalService,
    title: string,
    label: string,
    callback: any,
  ) {
    modalService.create({
      nzTitle: title,
      nzData: {
        label,
      },
      nzContent: SingleInputModalComponent,
      nzOnOk: (component: SingleInputModalComponent) =>
        callback(component.getValue()),
    });
  }

  public static removeHTMLTags(text: string): string {
    return text.replace(/<[^>]+>/g, '');
  }

  public static extractEnglishWords(
    paragraph: string,
    toLowerCase: boolean = false,
    minCharCount: number = 0,
  ): string[] {
    // Remove HTML tags
    paragraph = this.removeHTMLTags(paragraph);
    // Remove punctuation marks except hyphen and apostrophe
    const cleanedParagraph = paragraph
      .replace(/[^\w\s-'’]|_/g, '')
      .replace(/\s+/g, ' ');

    // Split the paragraph into an array of words
    const words = cleanedParagraph.split(' ');

    // Filter out non-English words and duplicates
    return Array.from(
      new Set(
        words
          .filter((word) => /^[a-zA-Z]+(?:[-'][a-zA-Z]+)*$/.test(word))
          .filter((word) => word.length >= minCharCount)
          .map((word) => (toLowerCase ? word.toLowerCase() : word)),
      ),
    );
  }

  //generate random string using uuid
  public static generateRandomString(): string {
    return uuid();
  }

  public static getUrlWithoutParams(
    router: Router,
    stripFirstSlash: boolean = true,
  ): string {
    let urlTree = router.parseUrl(router.url);
    urlTree.queryParams = {};
    urlTree.fragment = null; // optional

    return stripFirstSlash
      ? urlTree.toString().replace(/^\//, '')
      : urlTree.toString();
  }

  public static buildSolrQuerySentence(initialQuery: string) {
    //replace spaces with ? and add * at the end  and the beginning
    return '*' + initialQuery.replace(/\s+/g, '?') + '*';
  }

  public static getSpeechAssessmentCssClass(accuracy: number): string {
    if (accuracy > 90) {
      return 'speech-assessment-dark-green';
    } else if (accuracy > 80) {
      return 'speech-assessment-green';
    } else if (accuracy > 60) {
      return 'speech-assessment-yellow';
    } else if (accuracy > 50) {
      return 'speech-assessment-orange';
    } else if (accuracy > 20) {
      return 'speech-assessment-red';
    }
    return 'speech-assessment-red';
  }

  public static speechAssessmentToWordEvaluation(
    report: SpeechAssessmentReport,
  ): WordEvaluation[] {
    const wordsEvaluation: WordEvaluation[] = [];
    report.wordAssessments.forEach((wordAssessment) => {
      let wordCssClass =
        'fg-' +
        this.getSpeechAssessmentCssClass(wordAssessment.assessment.accuracy);
      let wordEvaluation: WordEvaluation = {
        word: wordAssessment.word,
        score: wordAssessment.assessment.accuracy,
        cssClass: wordCssClass,
      };
      wordsEvaluation.push(wordEvaluation);
    });

    return wordsEvaluation;
  }

  public static downloadMediaItem(
    identifier: number,
    mediaItem: MediaItem,
    makerMediaService: MakerMediaService,
    loadingService: LoadingService,
  ) {
    makerMediaService.download(mediaItem.id).subscribe({
      next: (res: Blob | MediaSource) => {
        this.createLinkAndDownload(res, identifier, mediaItem);
        loadingService.off();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  public static createLinkAndDownloadPdf(
    name: string,
    res: Blob | MediaSource,
  ) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(res);
    link.download = CommonService.stringToFilename(name) + '.pdf';
    link.click();
  }

  public static createLinkAndDownload(
    res: Blob | MediaSource,
    identifier: number,
    mediaItem: MediaItem,
  ) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(res);
    link.download =
      identifier +
      '-' +
      mediaItem.fileName +
      (['audio/mpeg', 'audio/mp3'].indexOf(mediaItem.mimeType) > -1
        ? '.mp3'
        : '.wav');
    link.click();
  }

  public static downloadMediaItemObservable(
    mediaItem: MediaItem,
    makerMediaService: MakerMediaService,
  ) {
    return makerMediaService.download(mediaItem.id);
  }

  public static downloadMediaItemMultiple(
    mediaItems: MediaItem[],
    makerMediaService: MakerMediaService,
    loadingService: LoadingService,
  ) {
    makerMediaService.downloadMultipleItems(mediaItems).subscribe({
      next: (res) => {
        const link = document.createElement('a');
        console.log('creating link', link);
        link.href = window.URL.createObjectURL(res);
        link.download =
          'download-' + this.stringToFilename(mediaItems[0].fileName) + '.zip';
        link.click();
        loadingService.off();
      },
    });
  }

  public static stringToFilename(text: string) {
    return text
      .toLowerCase()
      .replace(/[^a-z0-9]/gi, '-')
      .trim();
  }

  //extract first glossary of each key in the definition map
  public static extractFirstGlossary(definition: Definition) {
    const result: string[][] = [];
    if (definition && definition.definitions) {
      console.log(definition.definitions, typeof definition.definitions);
      Object.keys(definition.definitions).forEach((pos: string) => {
        //@ts-ignore
        const def = definition.definitions[pos];
        if (def.length > 0) {
          result.push([pos, def[0].glossary]);
        }
      });
    }

    return result;
  }

  public static playAudioFromUrl(url: string) {
    const audio = new Audio(url);
    audio.play();
  }

  public static openMediaPickerDialog(
    modalService: NzModalService,
    takeRawValue: boolean = false,
    includeConversation: boolean = true,
    includeMedia = true,
    onOk: any = () => {},
  ) {
    modalService.create({
      nzContent: MediaPickerDialogComponent,
      nzData: { takeRawValue, includeConversation, includeMedia },
      nzOnOk: (component: MediaPickerDialogComponent) => {
        onOk(component.getData());
      },
    });
  }
}
