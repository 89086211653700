<ng-container *ngIf="user">
  <h1>{{ "common.hello" | translate }}, {{ user["name"] }}</h1>

  <div class="col-12">
    <nz-card>
      <!--      User details card, including subscription details-->
      <h2>{{ "user.subscriptions.title" | translate }}</h2>
      <div *ngFor="let sub of subscriptions">
        <!--            Subscription sku and end date-->
        <table
          class="mat-mdc-table mdc-data-table__table cdk-table mat-elevation-z8"
        >
          <tbody>
            <tr
              class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row ng-star-inserted"
            >
              <td>SKU</td>
              <td>{{ sub["itemSku"] }}</td>
            </tr>
            <tr
              class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row ng-star-inserted"
            >
              <td>{{ "user.subscriptions.end-date" | translate }}</td>
              <td>
                {{ sub["endDate"] | date: "medium" }} ({{
                  "user.subscriptions.days-left" | translate
                }}
                {{ daysLeft(sub) }})
                <button nz-button nzType="primary" routerLink="/user/upgrade">
                  {{ "user.subscriptions.extend" | translate }}
                </button>
              </td>
            </tr>
            <tr
              class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row ng-star-inserted"
            >
              <td>{{ "user.subscriptions.status" | translate }}</td>
              <td>{{ sub["status"] }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="subscriptions.length === 0">
        <button nz-button nzType="primary" routerLink="/user/upgrade">
          {{ "user.subscriptions.subscribe" | translate }}
        </button>
      </div>
    </nz-card>
  </div>
</ng-container>
