<div class="audio-recorder">
  <button
    (click)="startRecording()"
    *ngIf="!isRecording"
    mat-mini-fab
    nzType="primary"
  >
    <mat-icon fontSet="material-symbols-outlined">mic</mat-icon>
  </button>
  <button
    (click)="stopRecording()"
    *ngIf="isRecording"
    color="accent"
    mat-mini-fab
  >
    <mat-icon fontSet="material-symbols-outlined">stop_circle</mat-icon>
  </button>

  <div *ngIf="showUserSpeechPlayer" class="user-audio-player">
    <audio controls id="player-{{ playerId }}">
      Your browser does not support the audio element.
    </audio>
    <mat-spinner
      *ngIf="showAudioLoading"
      class="audio-loading-spinner"
      nzType="primary"
    ></mat-spinner>
  </div>

  <div *ngIf="displayTTSAnimation" class="user-speak">
    <ukata-loading-anim
      *ngIf="isDoingPostProcessing"
      [bigBars]="false"
    ></ukata-loading-anim>
  </div>
</div>
