import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'ukata-level-picker',
  templateUrl: './level-picker.component.html',
  styleUrls: ['./level-picker.component.scss'],
})
export class LevelPickerComponent {
  @Input()
  level: string = '';

  @Output()
  onLevelChange = new EventEmitter<string>();
  @Input()
  required: boolean = false;
  protected readonly CommonService = CommonService;
}
