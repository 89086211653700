import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of, shareReplay } from 'rxjs';
import { Subscription } from '../../shared/interfaces/subscription';
import { User } from '../../shared/interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private subscriptions: Subscription[] = [];
  private _fetchedSubscriptions: boolean = false;
  private USER_API = environment.LEARNER_API + 'users/';

  constructor(private http: HttpClient) {}

  getSubscriptions(): Observable<Subscription[]> {
    if (!this._fetchedSubscriptions) {
      this._fetchedSubscriptions = true;
      return this.fetchSubscriptions();
    }
    return of(this.subscriptions);
  }

  updateSubscriptions(subscriptions: Subscription[]) {
    this.subscriptions = subscriptions;
  }

  fetchSubscriptions(): Observable<Subscription[]> {
    return this.http
      .get<Subscription[]>(this.USER_API + 'subscriptions')
      .pipe(shareReplay());
  }

  getUserDetails(): Observable<User> {
    return this.http.get<User>(this.USER_API + 'me').pipe(shareReplay());
  }
}
