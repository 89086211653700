import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpeechAssessmentDialogComponent } from '../../../learner/components/dialogs/speech-assessment-dialog/speech-assessment-dialog.component';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { PublicVocabularyListService } from '../../../core/services/public/public-vocabulary-list.service';
import { LoadingService } from '../../../core/services/loading.service';
import { CommonService } from '../../../core/services/common.service';
import { VocabularyListItem } from '../../interfaces/vocabulary/vocabulary-list-item';

@Component({
  selector: 'ukata-vocabulary-list-items-view',
  templateUrl: './vocabulary-list-items-view.component.html',
  styleUrls: ['./vocabulary-list-items-view.component.scss'],
})
export class VocabularyListItemsViewComponent implements OnInit {
  @Input() listId: string = '';

  @Input() displayPagination: boolean = true;

  //@TODO: implement display by list or card
  @Input() arrangement: string = 'list'; // 'list' | 'card';
  pageSize: number = 10;
  pageIndex: number = 1;
  totalItems: number = 0;
  totalPages: number = 0;
  vocabularyListItems: VocabularyListItem[] = [];
  protected readonly CommonService = CommonService;

  constructor(
    public loadingService: LoadingService,
    private publicVocabularyListService: PublicVocabularyListService,
    private dialog: MatDialog,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    let listIdChanges = changes['listId'];

    if (listIdChanges && listIdChanges.currentValue) {
      this.fetchListItems(this.pageSize, this.pageIndex);
    }
  }

  ngOnInit() {}

  openSpeechAssessmentDialog(ex: string) {
    this.dialog.open(SpeechAssessmentDialogComponent, {
      width: '500px',
      data: {
        script: ex,
      },
    });
  }

  public fetchListItems(pageSize: number, pageIndex: number) {
    const index = pageIndex - 1;
    this.publicVocabularyListService
      .listItems(this.listId, pageSize, index)
      .subscribe({
        next: (response) => {
          this.vocabularyListItems = response.data;
          this.totalItems = response.totalItems;
          this.totalPages = response.totalPages;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  onQueryParamsChange($event: NzTableQueryParams) {}
}
