<nz-tabset>
  <nz-tab *ngIf="includeConversation" nzTitle="Conversation">
    <h2>Conversations</h2>
    <nz-table
      (nzQueryParams)="onConversationQueryParamsChange($event)"
      [nzData]="conversations"
      [nzFrontPagination]="false"
      [nzLoading]="loadingService.loading$ | async"
      [nzPageIndex]="conversationPageIndex"
      [nzPageSizeOptions]="CommonService.makerPageSizeOptions"
      [nzPageSize]="conversationPageSize"
      [nzTotal]="totalConversationItems"
      nzShowPagination="true"
    >
      <thead>
        <tr>
          <th>Title</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of conversations">
          <td>
            <nz-radio-group
              (ngModelChange)="selectConversation(data)"
              [(ngModel)]="selectedConversation"
            >
              <label [nzValue]="data" nz-radio>{{ data.title }}</label>
            </nz-radio-group>
          </td>
          <td>{{ data.description }}</td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
  <nz-tab *ngIf="includeMedia" nzTitle="Media">
    <h2>Media</h2>
    <nz-row nzAlign="bottom">
      <nz-col>
        <nz-input-group [nzAddOnAfter]="suffixButton" nzSearch nzSize="large">
          <input
            [(ngModel)]="searchTerm"
            nz-input
            placeholder="input search text"
            type="text"
          />
        </nz-input-group>
        <ng-template #suffixButton>
          <button
            (click)="fetchMediaItems()"
            nz-button
            nzSearch
            nzSize="large"
            nzType="primary"
          >
            Search
          </button>
        </ng-template>
        <label
          [(ngModel)]="includeIsChild"
          nz-checkbox
          nzTooltipTitle="also include items that were created under other items, such as media item under conversations"
          >Include isChild</label
        >
      </nz-col>
    </nz-row>
    <nz-divider></nz-divider>
    <ukata-media-type-picker
      (onMediaTypeChange)="fetchMediaOnChange($event)"
      [mediaType]="mediaType"
    ></ukata-media-type-picker>
    <nz-table
      (nzQueryParams)="onMediaQueryParamsChange($event)"
      [nzData]="mediaItems"
      [nzFrontPagination]="false"
      [nzLoading]="loadingService.loading$ | async"
      [nzPageIndex]="mediaItemPageIndex"
      [nzPageSizeOptions]="CommonService.makerPageSizeOptions"
      [nzPageSize]="mediaItemPageSize"
      [nzTotal]="totalMediaItems"
    >
      <thead>
        <tr>
          <th>Title</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of mediaItems">
          <td>
            <nz-radio-group
              (ngModelChange)="selectMediaItem(data)"
              [(ngModel)]="selectedMediaItem"
            >
              <label [nzValue]="data" nz-radio>{{ data.title }}</label>
            </nz-radio-group>
          </td>
          <td>{{ data.description }}</td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
  <nz-tab nzTitle="Upload">
    <ukata-file-upload [multiple]="false"></ukata-file-upload>
  </nz-tab>
</nz-tabset>
