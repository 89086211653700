import { AfterViewInit, Component, Input, SimpleChange } from '@angular/core';
import { MediaItem } from '../../../shared/interfaces/media-item';
//@ts-ignore
import Plyr, { constructor } from 'plyr';
import { CommonService } from '../../../core/services/common.service';
import { PublicMediaService } from '../../../core/services/public/public-media.service';

@Component({
  selector: 'ukata-media-item-view',
  templateUrl: './media-item-view.component.html',
  styleUrls: ['./media-item-view.component.scss'],
})
export class MediaItemViewComponent implements AfterViewInit {
  @Input()
  isAudio: boolean = true;

  @Input()
  showScript: boolean = false;

  @Input()
  showScriptToggle: boolean = true;

  @Input()
  showSpeechAssessment: boolean = false;

  @Input()
  mediaItem: MediaItem | undefined;

  @Input()
  mediaItemId: string = '';

  @Input()
  playerOnly: boolean = false; //this variable is added because of the mediaItemId input. In this case, no script available
  //because the script is available ONLY when the mediaItem is passed

  playerId = CommonService.generateRandomString();
  isLoading = false;

  audioSource: { src: string; type: string } = {
    src: '',
    type: '',
  };

  private player: Plyr | undefined;

  constructor(private publicMediaService: PublicMediaService) {}

  ngAfterViewInit() {
    this.player = CommonService.createPlyrAudioPlayer(
      `#player-${this.playerId}`,
    );
    this.player.on('playing', () => {
      this.isLoading = false;
    });
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let mediaItemChange = changes['mediaItem'];
    let mediaIdChange = changes['mediaItemId'];
    console.log('media change: ', mediaItemChange, mediaIdChange);
    if (mediaItemChange && mediaItemChange.currentValue) {
      this.mediaItem = mediaItemChange.currentValue;
      this.loadMediaToPlay(this.mediaItem!.id);
    }
    if (mediaIdChange) {
      this.mediaItemId = mediaIdChange.currentValue;
      this.loadMediaToPlay(mediaIdChange.currentValue);
    }
  }

  loadMediaToPlay(mediaItemId: string) {
    if (!mediaItemId) {
      console.log('no media item');
      return;
    }
    console.log('loading media item: ', mediaItemId);
    this.isLoading = true;
    this.publicMediaService.fetchMediaUrl(mediaItemId).subscribe({
      next: (data) => {
        console.log('media item loaded', data);
        this.isLoading = false;
        if (this.isAudio) {
          this.audioSource = {
            src: data.url,
            type: data.mimeType,
          };
          this.player.source = {
            type: 'audio',
            sources: [
              {
                src: data.url,
                type: data.mimeType,
              },
            ],
          };
        } else {
          this.player.source = {
            type: 'video',
            sources: [
              {
                src: data.url,
                type: data.mimeType,
              },
            ],
          };
        }
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }
}
