import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface SingleLink {
  title: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private links: SingleLink[] = [];
  private linksSubject = new BehaviorSubject<SingleLink[]>(this.links);
  linksObservable = this.linksSubject.asObservable();

  constructor() {
    this.links.push({ title: 'Home', url: '/' });
    this.linksSubject.next(this.links);
  }

  public reset() {
    this.links = [{ title: 'Home', url: '/' }];
    this.linksSubject.next(this.links);
  }

  public addLink(title: string, url: string) {
    this.links.push({ title, url });
    this.linksSubject.next(this.links);
  }

  public getLinksObservable(): Observable<any> {
    return this.linksObservable;
  }

  public getLinks(): SingleLink[] {
    return this.links;
  }

  public removeLink() {
    this.links.pop();
    this.linksSubject.next(this.links);
  }

  public setLinks(links: SingleLink[]) {
    this.links = links;
    this.linksSubject.next(this.links);
  }

  public exists(url: string): boolean {
    return this.links.some((link) => link.url === url);
  }
}
