import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { MediaItem } from '../../shared/interfaces/media-item';
import { PageableResponse } from '../../shared/interfaces/pageable-response';
import { SingleSpeaker } from '../../maker/interfaces/audio-content/single-speaker';
import { Conversation } from '../../maker/interfaces/audio-content/conversation';
import { SingleValueResponse } from '../../shared/interfaces/single-value-response';

@Injectable({
  providedIn: 'root',
})
export class MakerMediaService {
  private MAKER_MEDIA_URL = environment.MAKER_API + 'media';

  constructor(private http: HttpClient) {}

  public upload(formData: FormData): Observable<MediaItem> {
    return this.http.post<MediaItem>(
      this.MAKER_MEDIA_URL + '/upload',
      formData
    );
  }

  public uploadMultiple(formData: FormData): Observable<MediaItem[]> {
    return this.http.post<MediaItem[]>(
      this.MAKER_MEDIA_URL + '/upload-multiple-file',
      formData
    );
  }

  public list(
    page: number,
    size: number,
    searchTerm: string,
    type: string,
    includeIsChild: boolean
  ): Observable<PageableResponse<MediaItem>> {
    return this.http.get<PageableResponse<MediaItem>>(
      this.MAKER_MEDIA_URL +
        `/list?page=${page}&size=${size}&type=${type}&searchTerm=${searchTerm}&includeIsChild=${includeIsChild}`
    );
  }

  public fetchMediaUrl(mediaId: string): Observable<SingleValueResponse> {
    return this.http
      .get<SingleValueResponse>(this.MAKER_MEDIA_URL + `/preview/${mediaId}`)
      .pipe(shareReplay());
  }

  public download(mediaId: string): Observable<any> {
    return this.http.get(this.MAKER_MEDIA_URL + `/download/${mediaId}`, {
      responseType: 'blob',
    });
  }

  public downloadMultipleIds(mediaIds: string[]): Observable<any> {
    return this.http.post(
      this.MAKER_MEDIA_URL + `/download/multiple-ids`,
      mediaIds,
      { responseType: 'blob' }
    );
  }

  public downloadMultipleItems(mediaItems: MediaItem[]): Observable<any> {
    return this.http.post(
      this.MAKER_MEDIA_URL + `/download/multiple-items`,
      mediaItems,
      { responseType: 'blob' }
    );
  }

  public createAudioFromSSML(request: {
    title: string;
    defaultLanguage: string;
    content: string;
    speechRate: number;
  }): Observable<MediaItem> {
    return this.http
      .post<MediaItem>(this.MAKER_MEDIA_URL + `/audio-ssml`, request)
      .pipe(shareReplay());
  }

  public createSingleAudio(
    request: { title: string; speaker: SingleSpeaker },
    checkWithSTT: boolean,
    fullCheck: boolean,
    skipHashCheck: boolean
  ): Observable<MediaItem> {
    return this.http
      .post<MediaItem>(
        this.MAKER_MEDIA_URL +
          `/audio?checkWithSTT=${checkWithSTT}&fullCheck=${fullCheck}&skipHashCheck=${skipHashCheck}`,
        request
      )
      .pipe(shareReplay());
  }

  public createConversation(
    request: Conversation,
    checkWithSTT: boolean,
    fullCheck: boolean
  ): Observable<MediaItem> {
    return this.http
      .post<MediaItem>(
        this.MAKER_MEDIA_URL +
          `/convo?checkWithSTT=${checkWithSTT}&fullCheck=${fullCheck}`,
        request
      )
      .pipe(shareReplay());
  }

  public search(
    searchText: string,
    page: number,
    size: number
  ): Observable<PageableResponse<MediaItem>> {
    return this.http.get<PageableResponse<MediaItem>>(
      this.MAKER_MEDIA_URL +
        `/search?page=${page}&size=${size}&term=${searchText}`
    );
  }
}
