import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
//@ts-ignore
import Plyr from 'plyr';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'ukata-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  url: SafeResourceUrl = '';
  plyrUrl: SafeResourceUrl = '';
  @Input()
  videoId: string = '';
  @Input()
  videoSource: string = 'YOUTUBE';
  playerId: string = CommonService.generateRandomString();
  private player: Plyr | undefined;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    const rawYoutubeUrl = 'https://www.youtube.com/embed/' + this.videoId;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(rawYoutubeUrl);
    // this.plyrUrl =this.sanitizer.bypassSecurityTrustResourceUrl(rawYoutubeUrl + '?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1');
    // this.player = new Plyr(`#player-${this.playerId}`);
  }
}
