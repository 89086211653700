import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { MediaResponse } from '../../../shared/interfaces/media-response';
import { MediaItem } from '../../../shared/interfaces/media-item';
import { ConversationPractice } from '../../../maker/interfaces/conversation-practice';

@Injectable({
  providedIn: 'root',
})
export class PublicMediaService {
  private PUBLIC_MEDIA_API = environment.PUBLIC_API + 'media';

  constructor(private http: HttpClient) {}

  public fetchMediaUrl(mediaId: string): Observable<MediaResponse> {
    return this.http
      .get<MediaResponse>(this.PUBLIC_MEDIA_API + `/preview/${mediaId}`)
      .pipe(shareReplay());
  }

  public getSingleMediaItem(mediaId: string): Observable<MediaItem> {
    return this.http
      .get<MediaItem>(this.PUBLIC_MEDIA_API + `/${mediaId}`)
      .pipe(shareReplay());
  }

  public getConversationPractice(id: string): Observable<ConversationPractice> {
    return this.http
      .get<ConversationPractice>(
        this.PUBLIC_MEDIA_API + `/conversation-practice/${id}`,
      )
      .pipe(shareReplay());
  }
}
