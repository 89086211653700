<nz-form-item>
  <nz-form-label>Visibility</nz-form-label>
  <nz-form-control>
    <nz-select
      (ngModelChange)="notifyVisibilityChange($event)"
      [(ngModel)]="visibility"
      [ngModelOptions]="{ standalone: true }"
      [required]="required"
    >
      <nz-option
        *ngFor="let visibility of CommonService.ItemVisibilities"
        [nzLabel]="visibility"
        [nzValue]="visibility"
      >
        {{ visibility }}
      </nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>
