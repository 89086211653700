import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PageableResponse } from '../../shared/interfaces/pageable-response';
import { SpeechAssessmentReport } from '../../shared/interfaces/assessment-result';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpeechAssessmentService {
  private SPEECH_ASSESSMENT_REPORT_URL =
    environment.LEARNER_API + 'speech-assessments';

  constructor(private http: HttpClient) {}

  list(
    page: number,
    size: number,
  ): Observable<PageableResponse<SpeechAssessmentReport>> {
    return this.http
      .get<
        PageableResponse<SpeechAssessmentReport>
      >(this.SPEECH_ASSESSMENT_REPORT_URL + `?page=${page}&size=${size}`)
      .pipe(shareReplay());
  }

  get(id: string): Observable<SpeechAssessmentReport> {
    return this.http
      .get<SpeechAssessmentReport>(this.SPEECH_ASSESSMENT_REPORT_URL + `/${id}`)
      .pipe(shareReplay());
  }
}
