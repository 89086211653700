import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PageableResponse } from '../../../shared/interfaces/pageable-response';
import { VocabularyList } from '../../../shared/interfaces/vocabulary/vocabulary-list';
import { VocabularyListItem } from '../../../shared/interfaces/vocabulary/vocabulary-list-item';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublicVocabularyListService {
  private PUBLIC_VOCABULARY_LIST = environment.PUBLIC_API + 'vocabulary-lists';

  constructor(private http: HttpClient) {}

  list(
    pageSize: number,
    pageIndex: number,
  ): Observable<PageableResponse<VocabularyList>> {
    return this.http.get<PageableResponse<VocabularyList>>(
      this.PUBLIC_VOCABULARY_LIST + '/?page=' + pageIndex + '&size=' + pageSize,
    );
  }

  listItems(
    listId: string,
    pageSize: number,
    pageIndex: number,
  ): Observable<PageableResponse<VocabularyListItem>> {
    return this.http.get<PageableResponse<VocabularyListItem>>(
      this.PUBLIC_VOCABULARY_LIST +
        '/' +
        listId +
        `/items?page=${pageIndex}&size=${pageSize}`,
    );
  }

  get(id: string) {
    return this.http.get<VocabularyList>(
      this.PUBLIC_VOCABULARY_LIST + '/' + id,
    );
  }
}
