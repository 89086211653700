import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { CreateConversationPracticeRequest } from '../interfaces/create-conversation-practice-request';
import { ConversationPractice } from '../interfaces/conversation-practice';
import { PageableResponse } from '../../shared/interfaces/pageable-response';

@Injectable({
  providedIn: 'root',
})
export class ConversationPracticeService {
  private API_URL = environment.MAKER_API + 'conversation-practice';

  constructor(private http: HttpClient) {}

  public get(id: string): Observable<ConversationPractice> {
    return this.http
      .get<ConversationPractice>(this.API_URL + `/${id}`)
      .pipe(shareReplay());
  }

  public create(
    request: CreateConversationPracticeRequest,
  ): Observable<ConversationPractice> {
    return this.http
      .post<ConversationPractice>(this.API_URL, request)
      .pipe(shareReplay());
  }

  public update(cp: ConversationPractice): Observable<ConversationPractice> {
    return this.http
      .put<ConversationPractice>(this.API_URL, cp)
      .pipe(shareReplay());
  }

  public delete(id: string): Observable<any> {
    return this.http.delete<any>(this.API_URL + `/${id}`).pipe(shareReplay());
  }

  public list(
    status: string,
    language: string,
    topics: string[],
    page: number,
    size: number,
  ): Observable<PageableResponse<ConversationPractice>> {
    return this.http
      .get<
        PageableResponse<ConversationPractice>
      >(this.API_URL + `/list?page=${page}&size=${size}&status=${status}&language=${language}&topics=${topics}`)
      .pipe(shareReplay());
  }
}
