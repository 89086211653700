import { Component, Input } from '@angular/core';

@Component({
  selector: 'ukata-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent {
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  @Input()
  futureEpochTime: number = 0;
  fiveMinsLeft = false;
  oneMinLeft = false;
  intervalHandler: any;

  constructor() {
    this.intervalHandler = setInterval(() => {
      const now = new Date().getTime();
      const difference = this.futureEpochTime - now;
      if (difference <= 300000) {
        this.fiveMinsLeft = true;
      }
      if (difference <= 60000) {
        this.oneMinLeft = true;
      }

      if (difference <= 0) {
        console.log('Time is up!');
        clearInterval(this.intervalHandler);
        return;
      }
      this.hours = Math.floor(difference / (1000 * 60 * 60));
      this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((difference % (1000 * 60)) / 1000);
    }, 1000);
  }
}
