import { Injectable } from '@angular/core';
// @ts-ignore
import { v4 as uuid } from 'uuid';
import { QuestionOption } from '../../shared/interfaces/question-option';
import { CreateMultipleChoiceQuestionRequest } from '../interfaces/create-multiple-choice-question-request';
import { Narration } from '../interfaces/audio-content/narration';
import { ToeicStatement } from '../interfaces/audio-content/toeic-statement';
import { CreateAudioQuestionRequest } from '../interfaces/create-audio-question-request';
import { SingleSpeaker } from '../interfaces/audio-content/single-speaker';
import { CreateUserInputQuestionRequest } from '../interfaces/create-user-input-question-request';
import { HtmlContentEditorWithTitleDialogComponent } from '../components/dialogs/html-content-editor-with-title-dialog/html-content-editor-with-title-dialog.component';
import { MediaPickerDialogComponent } from '../../shared/components/media-picker-dialog/media-picker-dialog.component';
import { QuizPickerDialogComponent } from '../components/dialogs/quiz-picker/quiz-picker-dialog.component';
import { PlainTextDialogComponent } from '../../shared/components/plain-text-dialog/plain-text-dialog.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CodeEditorDialogComponent } from '../components/dialogs/code-editor-dialog/code-editor-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class MakerCommonService {
  public static CommonSpeakers: string[] = [
    'en-US-AvaMultilingualNeural',
    'en-US-AnaNeural',
    'en-US-BrianMultilingualNeural',
    'en-US-EmmaMultilingualNeural',
    'en-GB-MaisieNeural',
    'es-ES-ElviraNeural',
    'fr-FR-DeniseNeural',
    'fr-FR-HenriNeural',
    'es-ES-AlvaroNeural',
    'es-ES-ArnauNeural',
  ];
  public static SpeechStyles = [
    'excited',
    'friendly',
    'terrified',
    'shouting',
    'unfriendly',
    'sad',
    'angry',
    'cheerful',
    'whispering',
    'hopeful',
    'default',
  ];
  public static pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  private static CommonFemaleSpeakers: string[] = [
    'en-US-JaneNeural',
    'en-US-JennyNeural',
  ];
  private static CommonMaleSpeakers: string[] = [
    'en-US-DavisNeural',
    'en-US-GuyNeural',
  ];

  constructor() {}

  //function to parse text into questions jsons
  public static parseMultipleChoiceQuestions(
    content: string,
  ): CreateMultipleChoiceQuestionRequest[] {
    console.log('parsing multiple choices questions');
    let lines = content.split('\n').map((line: string) => line.trim());
    let currentQuestion = undefined;
    const questions: CreateMultipleChoiceQuestionRequest[] = [];

    //filter all empty lines
    lines = lines.filter((line: string) => line.trim() !== '');
    console.log('parsing lines count', lines.length);

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i]; //already trimmed
      if (!line || line === '') continue;
      if (line.toLowerCase().startsWith('answer:')) {
        currentQuestion += line + '\n';

        //check if the next line is an explanation line, if so, add it to the current question
        if (
          i + 1 < lines.length &&
          lines[i + 1].toLowerCase().startsWith('explanation:')
        ) {
          currentQuestion += lines[i + 1] + '\n';
          i++;
        }
        const parsedQuestion = this.parseSingleMultipleChoiceQuestion(
          currentQuestion ?? '',
        );
        if (parsedQuestion) questions.push(parsedQuestion);
        currentQuestion = undefined;
        continue;
      }
      if (!currentQuestion) {
        currentQuestion = '';
      }
      currentQuestion += line + '\n';
    }
    return questions;
  }

  /**
   * Parse the following content format
   * The question content
   * probably span multiple lines
   * A. Option A
   * B. Option B
   * C. Option C
   * D. Option D
   * Answer: A
   * Explanation: The explanation
   * @param input
   */
  public static parseSingleMultipleChoiceQuestion(
    input: string,
  ): CreateMultipleChoiceQuestionRequest | undefined {
    console.log('parsing the single multiple choice question');
    if (!input) return undefined;
    const lines = input
      .split('\n')
      .filter((line: string) => line.trim() !== '');
    if (this.isOptionLine(lines[0].toLowerCase())) lines.unshift(''); //add empty line to the beginning of the array

    //find the first option line, the question content is from the first line to the line before the first option line
    let questionContent = this.getQuestionContent(lines);

    const firstOptionLineIndex = this.getFirstOptionLineIndex(lines);
    const lastOptionLineIndex = this.getLastOptionLineIndex(lines);
    if (firstOptionLineIndex === -1 || lastOptionLineIndex === -1) {
      console.log('first or last option line not found');
      return undefined;
    }
    const optionLines = lines.slice(
      firstOptionLineIndex,
      lastOptionLineIndex + 1,
    );
    console.log('optionLines lines: ', optionLines);
    const options = optionLines.map((option: string, index: number) => {
      //replace . with )
      //Extract the first three characters of the option to replace . with )
      //This prevents replacing subsequent . with ), which would be wrong
      const firstThreeChars = option.substring(0, 3);
      option =
        firstThreeChars.replace(/(\.|\/)/i, ')').toLowerCase() +
        option.substring(3); //replace ./ with )

      return {
        uuid: uuid(),
        content: option,
        contentType: 'text',
      };
    });
    let correctAnswersLine = this.getAnswerLine(lines);

    if (!correctAnswersLine) {
      console.log('Answer line not found');
      return undefined;
    }

    const correctAnswers = this.getCorrectAnswers(correctAnswersLine, options);
    const explanation = this.getExplanationLine(lines);
    //remove a), b), c), etc
    options.forEach((option: QuestionOption) => {
      option.content = option.content.replace(/^[a-z0-9]\)\s?/i, '');
    });

    console.log('all options', options);
    const type: string = 'multiple_choice';
    const point: number = 1;
    return {
      content: questionContent,
      type,
      options,
      correctAnswers,
      point,
      explanation,
    };
  }

  //parse a single user input question
  //there could be multiple blanks, separated by ,

  public static parseUserInputQuestion(input: string) {
    const lines = input
      .split('\n')
      .filter((line: string) => line.trim() !== '');
    let content = '';

    for (let i = 0; i < lines.length - 1; i++) {
      if (this.isContentLine(lines[i])) {
        content += lines[i] + '\n';
      }
    }
    if (content.trim() === '') {
      throw new Error('No content found');
    }
    content = content.trim();
    //except for the answer line, all the lines above belong to the content
    if (this.getAnswerLine(lines) === undefined) {
      throw new Error('No answers found');
    }

    const correctAnswers = this.parseUserInputAnswers(
      this.getAnswerLine(lines)!,
    );

    if (correctAnswers.length === 0) {
      throw new Error('No answers found');
    }

    const type = 'user_input';
    const gradingType = 'exact_match';
    const inputType = 'text';
    const point = 1;
    const data = {
      content,
      type,
      inputType,
      correctAnswers,
      gradingType,
      point,
    };
    console.log('parsed question', data);
    return data;
  }

  public static parseNarration(raw: string): Narration {
    return {
      type: 'narration',
      title: '',
      speaker: {
        text: raw,
      },
    };
  }

  public static parseConversation(raw: string) {
    //split the raw string into lines, then split each line into two parts by the first colon
    //the first part is the speaker, the second part is the content
    const lines = raw.trim().split('\n');
    const conversation = lines.map((line) => {
      const parts = line.split(':');
      return {
        speaker_id: parts[0],
        text: line.substring(parts[0].length + 1, line.length),
      };
    });

    return {
      type: 'conversation',
      title: '',
      conversation,
    };
  }

  public static parseMultipleToeicStatements(
    raw: string,
  ): CreateAudioQuestionRequest<ToeicStatement>[] {
    //this function parse multiple Toeic statements from a raw string
    //each question start with the line beginning with Statement: and end with the answer line, which starts with Answer:
    //the first step is to split the raw string into multiple questions (raw string format)
    //then feed this to parseSingleToeicStatement function to get the question object
    let lines = raw.trim().split('\n');
    let statementLinesIndexes: number[] = [];
    let answerLinesIndexes: number[] = [];

    //find the indexes of lines that start with Statement: and Answer:
    lines.forEach((line, index) => {
      if (
        line.toLowerCase().startsWith('statement:') ||
        line.toLowerCase().startsWith('question:')
      ) {
        statementLinesIndexes.push(index);
      }
      if (line.toLowerCase().startsWith('answer:')) {
        answerLinesIndexes.push(index);
      }
    });

    let rawQuestions = [];

    //split the raw string into multiple questions
    for (let i = 0; i < statementLinesIndexes.length; i++) {
      const startIndex = statementLinesIndexes[i];
      const endIndex = answerLinesIndexes[i];
      const question = lines
        .slice(startIndex, endIndex + 1)
        .join('\n')
        .trim();
      rawQuestions.push(question);
    }

    //parse each question
    return rawQuestions.map((rawQuestion) => {
      return this.parseSingleToeicStatement(rawQuestion);
    });
  }

  //This function parse raw string to a toeic statement and multiple choice question
  public static parseSingleToeicStatement(
    raw: string,
  ): CreateAudioQuestionRequest<ToeicStatement> {
    /*
        Statement: "I had a great time at the party last night."
    Responses:
    A) "I don't think I can go to the party."
    B) "I'm glad you enjoyed it!"
    C) "The party was really boring."

    Answer: B) "I'm glad you enjoyed it!"
         */

    //filter blank lines and lines start with 'responses:'
    let lines = raw
      .trim()
      .split('\n')
      //remove double quotes
      .map((line) => line.replace(/"/g, '').trim())
      .filter(
        (line) => line.trim() !== '' && line.toLowerCase() !== 'responses:',
      );

    //GPT seems to select answer A most of the time. We need to make the order random so the choice would not be
    //weird to the taker
    //shuffle the lines between the statement and the answer
    lines = this.shuffleOptionLines(lines);

    const filteredRaw = lines.join('\n');
    const firstLine = lines[0];
    let statementLine = '';
    if (firstLine.indexOf(':') !== -1) {
      const prompt = firstLine.split(':')[0];
      statementLine = firstLine.substring(prompt.length + 1, firstLine.length);
    } else {
      statementLine = firstLine;
    }

    statementLine = statementLine.trim();

    let multipleChoiceQuestion =
      this.parseSingleMultipleChoiceQuestion(filteredRaw);

    //throw exception if there is no answer
    if (
      !multipleChoiceQuestion ||
      multipleChoiceQuestion.correctAnswers.length === 0
    )
      throw new Error('Cannot parse multiple choice question. No answer found');

    if (!multipleChoiceQuestion)
      throw new Error('Cannot parse multiple choice question');
    multipleChoiceQuestion.content = ''; //remove the statement from the content

    const options: SingleSpeaker[] = multipleChoiceQuestion.options.map(
      (option) => {
        return {
          text: option.content,
        };
      },
    );

    const statement: ToeicStatement = {
      type: 'toeic-statement',
      statement: { text: statementLine },
      options,
    };

    return {
      audioContent: statement,
      question: multipleChoiceQuestion,
    };
  }

  public static parseConversationPractice(
    raw: string,
    forceUseCommonSpeakers: boolean,
  ): SingleSpeaker[] {
    const separator = '|';
    //split the raw string into lines, then split each line into two or three parts by the pipe character (|)
    //the first part is the speaker, the second part (if there are 2 parts) is the content.
    //However, if there are three parts, the second part is the style (sad, happy, angry, etc), the third part is the content
    if (raw.trim() === '') return [];
    let lines = raw
      .trim()
      .split('\n')
      .filter((line) => line.trim() !== '');
    //insert the speaker if the line does not have one
    lines = lines.map((line) => {
      if (line.indexOf(separator) === -1) {
        return `Davis${separator}${line}`;
      }
      return line;
    });

    //assert that every line must have the same format
    const firstLineParts = lines[0].split(separator);
    //loop over the lines to check if they have the same format
    console.log(lines);
    if (lines.length > 1) {
      for (let i = 1; i < lines.length; i++) {
        const lineParts = lines[i].split(separator);
        if (lineParts.length !== firstLineParts.length)
          throw new Error(
            `Line ${i + 1} has different format than the first line`,
          );
      }
    }
    //alternate between two speakers
    let anotherSpeaker = false;
    return lines.map((line) => {
      const parts = line.split(separator);
      anotherSpeaker = !anotherSpeaker;
      let speakerId = parts[0];
      if (forceUseCommonSpeakers && !this.CommonSpeakers.includes(speakerId)) {
        if (this.getSpeakerIdByName(speakerId)) {
          speakerId = this.getSpeakerIdByName(speakerId)!;
        } else {
          speakerId = this.CommonSpeakers[anotherSpeaker ? 1 : 0];
        }
      }
      let text = parts.length === 3 ? parts[2] : parts[1];

      //remove the " at the beginning and the end of the text, if any
      text = text.trim().replace(/^"/, '').replace(/"$/, '').trim();

      return {
        speaker_id: speakerId,
        style: parts.length === 3 ? parts[1] : 'default',
        text: text,
      } as SingleSpeaker;
    });
  }

  static parseMultipleUserInputQuestions(
    questionsText: string,
  ): CreateUserInputQuestionRequest[] {
    /*
        The format of multiple questions is as follows:
         She usually (cooks / is cooking / has cooked) dinner at this time.
         Answer: cooks
         We often (go / are going / have gone) to the park on Sundays.
         Answer: go
         He (plays / has played / is playing) the guitar since he was a child.
         Answer: has played
         They usually (take / are taking / have taken) the bus to school, but today they walked.
         Answer: take
         I (don't watch / am not watching / haven't watched) that TV show before.
         Answer: haven't watched

         we need to split the text by the word Answer: and then parse each question separately.

         */

    //first, split the content to lines and remove empty lines
    let lines = questionsText
      .trim()
      .split('\n')
      .filter((line) => line.trim().length > 0);
    let questions = [];

    let question = '';
    for (let i = 0; i < lines.length; i++) {
      question += '\n' + lines[i];
      if (this.isAnswerLine(lines[i])) {
        questions.push(question);
        question = '';
      }
    }

    return questions.map((question) => {
      return this.parseUserInputQuestion(question);
    });
  }

  public static openPlainTextEditor(
    modalService: NzModalService,
    title: string,
    content: string | undefined,
    onOk: any,
    label: string = '',
    placeholder: string = '',
  ) {
    modalService.create({
      nzTitle: title,
      nzContent: PlainTextDialogComponent,
      nzData: {
        content,
        label,
        placeholder,
      },
      nzOnOk: (component: PlainTextDialogComponent) => {
        const content = component.getContent();
        console.log('data from plain text', content);
        onOk(content);
      },
    });
  }

  public static openHtmlEditor(
    modal: NzModalService,
    title: string,
    content: string | undefined,
    onOk: any,
  ) {
    modal.create({
      nzTitle: title,
      nzContent: HtmlContentEditorWithTitleDialogComponent,
      nzData: { title, content },
      nzOnOk: (component: HtmlContentEditorWithTitleDialogComponent) => {
        onOk(component.getData());
      },
    });
  }

  public static openCodeEditor(
    modal: NzModalService,
    content: string | undefined,
    onOk: any,
  ) {
    modal.create({
      nzContent: CodeEditorDialogComponent,
      nzData: { content },
      nzWidth: '80vw',
      nzBodyStyle: { height: '80vh' },

      nzOnOk: (component: CodeEditorDialogComponent) => {
        onOk(component.getData());
      },
    });
  }

  public static openSingleTextFieldEditor(
    title: string = 'Edit field',
    modal: NzModalService,
    content: string,
    onOk: {
      (data: string): void;
    },
    label: string = '',
  ) {
    modal.create({
      nzTitle: title,
      nzContent: PlainTextDialogComponent,
      nzData: {
        content,
        label,
      },
      nzOnOk: (component: PlainTextDialogComponent) => {
        onOk(component.data.content);
      },
    });
  }

  public static openMediaPickerDialog(
    modalService: NzModalService,
    takeRawValue: boolean = false,
    includeConversation: boolean = true,
    includeMedia: boolean = true,
    onOk: any,
  ) {
    modalService.create({
      nzTitle: 'Pick media',
      nzContent: MediaPickerDialogComponent,
      nzData: {
        takeRawValue,
        includeConversation,
        includeMedia,
      },
      nzOnOk: (component: MediaPickerDialogComponent) => {
        console.log('component.returnData', component.returnData);
        onOk(component.returnData);
      },
    });
  }

  public static openQuizPickerDialog(modalService: NzModalService, onOk: any) {
    modalService.create({
      nzTitle: 'Pick quiz',
      nzContent: QuizPickerDialogComponent,
      nzOnOk: (component: QuizPickerDialogComponent) => {
        onOk(component.getSelectedQuiz());
      },
    });

    // return dialog.open(QuizPickerDialogComponent, {
    //   width: '700px',
    // }).afterClosed();
  }

  //this method take the speaker name, Jenny for example and check in CommonSpeakers array to see if there is a speaker

  private static getCorrectAnswers(
    correctAnswersLine: string,
    options: {
      uuid: any;
      contentType: string;
      content: string;
    }[],
  ) {
    correctAnswersLine = correctAnswersLine
      .replace(/(\.|\/)/i, ')')
      .replace('(', '')
      .toLowerCase();
    const correctAnswerABC = correctAnswersLine
      .split(':')[1]
      .trim()
      .split(',')
      .map((t: string) => t.trim().split(')')[0].trim());

    return options
      .filter((option: QuestionOption) => {
        const optionIndex = option.content.split(')')[0].trim().toLowerCase();
        return correctAnswerABC.includes(optionIndex);
      })
      .map((option: QuestionOption) => {
        return option.uuid;
      });
  }

  private static getAnswerLine(lines: string[]) {
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (line.toLowerCase().startsWith('answer:')) return line;
    }
    return undefined;
  }

  private static getExplanationLine(lines: string[]) {
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (line.toLowerCase().startsWith('explanation:'))
        return line.substring('explanation:'.length).trim();
    }
    return undefined;
  }

  private static getFirstOptionLineIndex(lines: string[]) {
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (this.isOptionLine(line)) return i;
    }
    return -1;
  }

  private static getLastOptionLineIndex(lines: string[]) {
    for (let i = lines.length - 1; i >= 0; i--) {
      const line = lines[i];
      if (this.isOptionLine(line)) return i;
    }
    return -1;
  }

  private static getQuestionContent(lines: string[]) {
    let questionContent = [];
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (this.isOptionLine(line)) break;
      questionContent.push(line);
    }
    return questionContent.join('\n');
  }

  //that contains the name Jenny. If there is, return the speaker id, otherwise return undefined
  private static getSpeakerIdByName(speakerName: string) {
    const speaker = this.CommonSpeakers.find(
      (speaker) =>
        speaker.toLowerCase().indexOf(speakerName.toLowerCase()) !== -1,
    );
    if (speaker) return speaker;
    return undefined;
  }

  private static isOptionLine(line: string): boolean {
    line = line.toLowerCase();
    // Match any line that starts with a or b or c or d or 1 or 2 or 3 or 4 followed by a period and a space
    const optionRegex = /^(a|b|c|d|[1-4])(\.|\))\s/;
    return optionRegex.test(line);
  }

  private static shuffleOptionLines(lines: string[]) {
    //first, get the option lines indexes and store in one array
    let middleLines = this.shuffle(lines.slice(1, lines.length - 1));

    //then, put the first and last line back to the array
    middleLines.unshift(lines[0]);
    middleLines.push(lines[lines.length - 1]);

    return middleLines;
  }

  private static shuffle(array: string[]) {
    return array
      .map((a) => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value);
  }

  private static isAnswerLine(line: string) {
    if (!line) return false;
    return line.trim().toLowerCase().startsWith('answer:');
  }

  private static isExplanationLine(line: string) {
    if (!line) return false;
    return line.trim().toLowerCase().startsWith('explanation:');
  }

  private static isContentLine(line: string) {
    if (!line) return false;

    return (
      !this.isAnswerLine(line) &&
      !this.isExplanationLine(line) &&
      !this.isOptionLine(line)
    );
  }

  private static parseUserInputAnswers(answerLine: string) {
    return answerLine
      .trim()
      .substring('answer:'.length)
      .trim()
      .split(',')
      .map((t: string) => t.trim());
  }
}
