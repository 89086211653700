import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Quiz } from '../../../../shared/interfaces/quiz';
import { LoadingService } from '../../../../core/services/loading.service';
import { QuizMakerService } from '../../../services/quiz-maker.service';
import { MakerCommonService } from '../../../services/maker-common.service';
import { CommonService } from '../../../../core/services/common.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'ukata-quiz-picker',
  templateUrl: './quiz-picker-dialog.component.html',
  styleUrls: ['./quiz-picker-dialog.component.scss'],
})
export class QuizPickerDialogComponent implements OnInit {
  // This picker is used to select a quiz that the user owns.
  quizzes: Quiz[] = [];
  pageSize: number = 5;
  pageIndex: number = 1;
  totalItems: number = 0;
  selectedQuiz: Quiz | undefined = undefined;
  searchTerm: string = '';
  //Do not delete, webstorm doesn't recognize this function is in use
  selectedQuizId: string = '';
  protected readonly Math = Math;
  protected readonly MakerCommonService = MakerCommonService;
  protected readonly CommonService = CommonService;

  constructor(
    public loadingService: LoadingService,
    private quizMakerService: QuizMakerService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.searchQuiz();
  }

  onNoClick(): void {}

  searchQuiz(
    pageIndex: number = this.pageIndex,
    pageSize: number = this.pageSize,
  ) {
    const index = pageIndex - 1;
    this.quizMakerService
      .searchQuizzes(this.searchTerm, ['PUBLISHED'], index, pageSize)
      .subscribe({
        next: (data) => {
          if (!data) {
            return;
          }
          this.quizzes = data.data;
          this.totalItems = data.totalItems;
          this.cd.detectChanges();
        },
        error: (error) => {
          console.log('error: ', error);
        },
      });
  }

  getSelectedQuiz() {
    return this.selectedQuiz;
  }

  onQueryParamsChange($event: NzTableQueryParams) {
    if (
      this.pageIndex == $event.pageIndex &&
      this.pageSize == $event.pageSize
    ) {
      return;
    }

    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.searchQuiz();
  }
}
