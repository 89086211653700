<nz-form-item>
  <nz-form-label>{{ label }}</nz-form-label>
  <nz-select
    (ngModelChange)="selected($event)"
    [(ngModel)]="selectedLanguage"
    [nzAllowClear]="true"
    [nzShowSearch]="true"
  >
    <nz-option
      *ngFor="let language of options"
      [nzLabel]="language"
      [nzValue]="language"
    >
    </nz-option>
  </nz-select>
</nz-form-item>
