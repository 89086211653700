import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ContentChange } from 'ngx-quill';

@Component({
  selector: 'ukata-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
})
export class HtmlEditorComponent implements AfterViewInit {
  @Input()
  initialContent: string = ''; //initial html content

  @Output()
  onContentChange: EventEmitter<string> = new EventEmitter();

  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'], // remove formatting button
        ['link'],
      ],
    },
    keyboard: {
      bindings: {},
    },
  };
  domReady: boolean = false;

  constructor() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.domReady = true;
    }, 400);
  }

  onSelectionChanged = (event: any) => {};

  onContentChanged = (event: any) => {
    //console.log(event.html);
    this.onContentChange.emit(event.html);
  };

  notifyContentChange($event: ContentChange) {
    this.onContentChange.emit($event.text);
  }
}
