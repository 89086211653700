import { AfterViewInit, Component, Input } from '@angular/core';
import { MediaResponse } from '../../../../shared/interfaces/media-response';

//@ts-ignore
import { v4 as uuid } from 'uuid';
//@ts-ignore
import Plyr from 'plyr';
import { CommonService } from '../../../../core/services/common.service';
import { SseService } from '../../../../core/services/sse.service';
import { PublicDictionaryService } from '../../../../core/services/public/public-dictionary.service';
import { AuthGuard } from '../../../../core/services/auth-guard.service';
import { NotificationService } from '../../../../core/notifications/notification.service';

interface TextToSpeechResponse {
  data: MediaResponse;
  requestId: string;
}

@Component({
  selector: 'ukata-text-to-speech',
  templateUrl: './text-to-speech.component.html',
  styleUrls: ['./text-to-speech.component.scss'],
})
export class TextToSpeechComponent implements AfterViewInit {
  requestId: string = uuid();
  @Input()
  script: string = '';

  mediaResponse: MediaResponse | undefined;
  @Input()
  displayScript: boolean = true;

  playerId = CommonService.generateRandomString();

  isLoading: boolean = false;
  playerClass: string = 'd-none';
  private player: Plyr | undefined;

  constructor(
    private publicDictionaryService: PublicDictionaryService,
    private authService: AuthGuard,
    private notificationService: NotificationService,
    private sseService: SseService,
  ) {}

  ngAfterViewInit(): void {
    this.sseService.subscribeToSSE().subscribe({
      next: (res) => {
        if (res.topic.toLowerCase() === 'text_to_speech') {
          console.log('text to speech response', res);
          const ttsRes = res.data as TextToSpeechResponse;
          if (ttsRes.requestId !== this.requestId) {
            console.log(
              'request id mismatch, ignoring tts response',
              ttsRes.requestId,
              this.requestId,
            );
            return;
          }
          this.isLoading = false;
          this.mediaResponse = ttsRes.data;
          this.playerClass = '';
          this.player.source = {
            type: 'audio',
            sources: [
              {
                src: this.mediaResponse.url,
                type: this.mediaResponse.mimeType,
              },
            ],
          };
          this.player.play();
        }
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
    this.player = CommonService.createPlyrAudioPlayer(
      `#player-${this.playerId}`,
    );
    this.player.on('canplaythrough', () => {
      console.log('can play through');
      this.isLoading = false;
    });
  }

  speak() {
    if (!this.script) {
      console.log('No script to speak');
      return;
    }

    if (!this.authService.isAuthenticated()) {
      console.log('User not authenticated');
      this.notificationService.info('Please login to use this feature');
      return;
    }
    this.isLoading = true;
    this.playerClass = 'd-none';
    console.log('sending request', this.script, this.requestId);
    this.publicDictionaryService
      .fetchPronunciation(this.script, this.requestId)
      .subscribe({
        next: (res) => {
          console.log('request sent. waiting...', res);
        },
        error: (err) => {
          console.log(err);
          // this.isLoading = false;
        },
      });
  }
}
