import { Component, Input, OnInit } from '@angular/core';

//@ts-ignore
import { v4 as uuid } from 'uuid';
import { AssessmentReportDetailDialogComponent } from '../../../../learner/components/pronunciation/assessment-report/assessment-report-detail-dialog/assessment-report-detail-dialog.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CommonService } from '../../../../core/services/common.service';
import {
  SpeechAssessmentReport,
  WordEvaluation,
} from '../../../interfaces/assessment-result';

@Component({
  selector: 'ukata-speech-assessment',
  templateUrl: './speech-assessment.component.html',
  styleUrls: ['./speech-assessment.component.scss'],
})
export class SpeechAssessmentComponent implements OnInit {
  requestId: string = uuid();
  @Input()
  script: string = '';

  @Input()
  showUserAudio: boolean = false;
  report: SpeechAssessmentReport | undefined;

  wordsEvaluation: WordEvaluation[] = [];
  @Input()
  showReportIndividualWordsTable: boolean = false;
  @Input()
  showScript: boolean = true;
  protected readonly CommonService = CommonService;

  constructor(private modalService: NzModalService) {}

  ngOnInit(): void {}

  handleSpeechAssessmentResult($event: {
    parentRequestId: string;
    report: SpeechAssessmentReport;
  }) {
    if (this.requestId === $event.parentRequestId) {
      console.log('processing result', this.requestId, $event.report);
      this.report = $event.report;
      this.evaluateWords($event.report);
    }
  }

  handleOnRecordingEventChange($event: {
    parentRequestId: string;
    event: string;
  }) {
    if (
      $event.parentRequestId === this.requestId &&
      $event.event === 'started'
    ) {
      this.report = undefined;
      this.wordsEvaluation = [];
    }
  }

  viewDetails(reportId: string) {
    this.modalService.create({
      nzTitle: 'Assessment Report',
      nzContent: AssessmentReportDetailDialogComponent,
      nzData: {
        reportId,
      },
    });
  }

  private evaluateWords(report: SpeechAssessmentReport) {
    this.wordsEvaluation =
      CommonService.speechAssessmentToWordEvaluation(report);
  }
}
