import { Component, Input, Inject } from '@angular/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
@Component({
  selector: 'ukata-common-modals',
  standalone: true,
  imports: [],
  template: ``,
  styleUrl: './common-modals.component.scss',
})
export class CommonModalsComponent {}

@Component({
  selector: 'single-input-modal',
  standalone: true,
  imports: [NzFormModule, FormsModule, NzInputModule],
  template: `
    <nz-form-item>
      <label>{{ data.label }}</label>
      <input required nz-input [(ngModel)]="value" />
    </nz-form-item>
  `,
  styleUrl: './common-modals.component.scss',
})
export class SingleInputModalComponent {
  value: string = '';

  constructor(
    @Inject(NZ_MODAL_DATA)
    public data: {
      label: string;
    },
  ) {}
  getValue() {
    return this.value;
  }
}
