import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { PageableResponse } from '../../shared/interfaces/pageable-response';
import { Quiz } from '../../shared/interfaces/quiz';
import { FullQuiz } from '../../shared/interfaces/full-quiz';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { QuizSearchDto } from '../interfaces/quiz/quiz-search-dto';
import { CreateMultipleChoiceQuestionsPrompt } from '../interfaces/quiz/create-multiple-choice-questions-prompt';

@Injectable({
  providedIn: 'root',
})
export class QuizMakerService {
  private QUIZ_API = environment.MAKER_API + 'quizzes';

  constructor(private http: HttpClient) {}

  getAllQuizzes(
    status: string[],
    page: number,
    size: number,
  ): Observable<PageableResponse<Quiz>> {
    return this.http
      .get<
        PageableResponse<Quiz>
      >(this.QUIZ_API + `/list?page=${page}&size=${size}&status=${status}`)
      .pipe(shareReplay());
  }

  searchQuizzes(
    term: string,
    status: string[],
    page: number,
    size: number,
  ): Observable<PageableResponse<Quiz>> {
    return this.http.get<PageableResponse<Quiz>>(
      this.QUIZ_API +
        `/search?term=${term}&page=${page}&size=${size}&status=${status}`,
    );
  }

  createQuiz(quiz: Quiz): Observable<Quiz> {
    return this.http.post<Quiz>(this.QUIZ_API, quiz);
  }

  updateQuiz(quiz: Quiz): Observable<Quiz> {
    return this.http.put<Quiz>(this.QUIZ_API, quiz);
  }

  loadQuizToEdit(quizId: string): Observable<FullQuiz> {
    return this.http.get<FullQuiz>(this.QUIZ_API + `/full/${quizId}`);
  }

  deleteQuiz(quizId: string): Observable<any> {
    return this.http.delete(this.QUIZ_API + `/${quizId}`);
  }

  clearLearnerQuizCache(quizId: string): Observable<any> {
    return this.http.delete(this.QUIZ_API + `/cache/${quizId}`);
  }

  getQuizInList(
    quizIds: string[],
    page: number,
    size: number,
  ): Observable<PageableResponse<Quiz>> {
    return this.http.post<PageableResponse<Quiz>>(
      this.QUIZ_API + `/include?page=${page}&size=${size}`,
      quizIds,
    );
  }

  getQuizNotInList(
    quizIds: string[],
    page: number,
    size: number,
  ): Observable<PageableResponse<Quiz>> {
    return this.http.post<PageableResponse<Quiz>>(
      this.QUIZ_API + `/exclude?page=${page}&size=${size}`,
      quizIds,
    );
  }

  search(dto: QuizSearchDto, pageIndex: number, pageSize: number) {
    return this.http
      .post<
        PageableResponse<Quiz>
      >(this.QUIZ_API + `/search?page=${pageIndex}&size=${pageSize}`, dto)
      .pipe(shareReplay());
  }

  createMultipleQuestionGroupForQuiz(
    quizId: string,
    prompts: CreateMultipleChoiceQuestionsPrompt[],
  ): Observable<FullQuiz> {
    return this.http.post<FullQuiz>(
      this.QUIZ_API + `/prompt/${quizId}`,
      prompts,
    );
  }

  downloadQuizAnswersPdf(quizId: string): Observable<Blob> {
    return this.http.get<Blob>(
      this.QUIZ_API + `/download-pdf/answer/${quizId}`,
      {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          Accept: 'application/pdf',
        }),
      },
    );
  }

  downloadQuizPdf(quizId: string): Observable<Blob> {
    return this.http.get<Blob>(this.QUIZ_API + `/download-pdf/${quizId}`, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        Accept: 'application/pdf',
      }),
    });
  }
}
