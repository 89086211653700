<div class="conversation-preview">
  <audio
    #audioMediaPlayer
    [src]="mediaUrl"
    autoplay
    controls
    style="display: none"
  >
    Your browser does not support the audio element.
  </audio>
  <div *ngIf="!showPlayerOnly">
    <div *ngIf="showScriptToggle">
      <mat-slide-toggle (change)="showScript = !showScript" nzType="primary">
        Script
      </mat-slide-toggle>
    </div>
    <p></p>
    <ul *ngIf="conversation && showScript">
      <li
        *ngFor="let media of conversation!.conversation; let i = index"
        class="d-flex flex-row chat-{{ i % 2 === 0 ? 'left' : 'right' }}"
      >
        <div
          class="chat-bubble {{ i % 2 === 0 ? 'left' : 'right' }} {{
            i == currentPlayingIndex - 1 ? 'active-chat' : ''
          }}"
        >
          <div style="flex: 1">
            {{ media.transcript }}
          </div>
          <div>
            <div style="display: inline-block">
              <ukata-speech-assessment
                [script]="media.transcript"
                [showScript]="false"
              ></ukata-speech-assessment>
            </div>
            <mat-icon (click)="previewMedia(media)" style="cursor: pointer"
              >volume_up
            </mat-icon>
            <ukata-loading-anim
              *ngIf="isAudioLoading && i == currentPlayingIndex - 1"
              [bigBars]="false"
            ></ukata-loading-anim>
          </div>
        </div>
      </li>
    </ul>
    <p class="clearfix"></p>
  </div>

  <button
    (click)="playAll()"
    *ngIf="!isPlayingConversation"
    nz-button
    nzType="primary"
  >
    <mat-icon fontSet="material-symbols-outlined">play_circle</mat-icon>
    Play
  </button>
  <button
    (click)="stopPlayingAll()"
    *ngIf="isPlayingConversation"
    color="accent"
    nz-button
  >
    <mat-icon fontSet="material-symbols-outlined">stop_circle</mat-icon>
    Stop
  </button>
  <p></p>
</div>
