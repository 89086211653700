import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { SingleValueResponse } from '../../../shared/interfaces/single-value-response';
import { Narration } from '../../../maker/interfaces/audio-content/narration';
import { Definition } from '../../../shared/interfaces/dictionary/definition';

@Injectable({
  providedIn: 'root',
})
export class PublicDictionaryService {
  private DICTIONARY_API = environment.PUBLIC_API + 'dictionary';

  constructor(private http: HttpClient) {}

  fetchDefinition(word: string, sourceAndTargetLanguages: Map<string, string>) {
    return this.http
      .post<
        Definition[]
      >(this.DICTIONARY_API + `/definition/${word}`, Object.fromEntries(sourceAndTargetLanguages))
      .pipe(shareReplay());
  }

  fetchPronunciation(
    text: string,
    requestId: string,
  ): Observable<SingleValueResponse> {
    const narration: Narration = {
      speaker: {
        text: text.trim(),
      },
      title: '',
      type: 'narration',
    };
    return this.http
      .post<SingleValueResponse>(
        this.DICTIONARY_API + `/pronunciation/${requestId}`,
        narration,
      )
      .pipe(shareReplay());
  }

  fetchIPAPronunciation(
    ipa: string,
    requestId: string,
  ): Observable<SingleValueResponse> {
    const narration: Narration = {
      speaker: {
        text: ipa.trim(),
      },
      title: ipa,
      type: 'ipa-syllable',
    };
    return this.http
      .post<SingleValueResponse>(
        this.DICTIONARY_API + `/pronunciation/${requestId}`,
        narration,
      )
      .pipe(shareReplay());
  }

  fetchExamples(
    query: string,
    minWord: number = 0,
    maxWord: number = 100,
    collection: string = 'english_sentences',
  ) {
    return this.http
      .get<
        string[]
      >(this.DICTIONARY_API + `/examples?query=${query}&min_word=${minWord}&max_word=${maxWord}`)
      .pipe(shareReplay());
  }
}
