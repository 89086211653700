import { Component, Inject } from '@angular/core';
import {
  PhonemeAssessment,
  SpeechAssessmentReport,
  SyllableAssessment,
  WordAssessment,
} from '../../../../../shared/interfaces/assessment-result';
import { SpeechAssessmentService } from '../../../../services/speech-assessment.service';
import { CommonService } from '../../../../../core/services/common.service';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

interface PhonemeAssessmentWithCSS extends PhonemeAssessment {
  cssClass: string;
}

interface SyllableAssessmentWithCSS extends SyllableAssessment {
  cssClass: string;
}

interface WordAssessmentWithCSS extends WordAssessment {
  cssClass: string;
}

@Component({
  selector: 'ukata-assessment-report-detail-dialog',
  templateUrl: './assessment-report-detail-dialog.component.html',
  styleUrls: ['./assessment-report-detail-dialog.component.scss'],
})
export class AssessmentReportDetailDialogComponent {
  report: SpeechAssessmentReport | undefined;
  currentWordAssessment: WordAssessment | undefined;

  currentPhonemeAssessment: PhonemeAssessmentWithCSS[] = [];
  currentSyllableAssessment: SyllableAssessmentWithCSS[] = [];
  wordsAssessmentWithCSS: WordAssessmentWithCSS[] = [];

  constructor(
    private speechAssessmentService: SpeechAssessmentService,
    @Inject(NZ_MODAL_DATA) public data: { reportId: string },
  ) {}

  ngOnInit(): void {
    this.speechAssessmentService.get(this.data.reportId).subscribe({
      next: (report) => {
        this.report = report;
        this.wordsAssessmentWithCSS = report.wordAssessments.map((wa) => {
          return {
            ...wa,
            cssClass:
              'fg-' +
              CommonService.getSpeechAssessmentCssClass(wa.assessment.accuracy),
          };
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  loadDetailedReportForWord(wa: WordAssessment) {
    this.currentWordAssessment = wa;
    console.log('loading...');
    if (wa.phonemeAssessments) {
      this.currentPhonemeAssessment = wa.phonemeAssessments.map((pa) => {
        return {
          ...pa,
          cssClass:
            'sa-bg-' +
            CommonService.getSpeechAssessmentCssClass(pa.assessment.accuracy),
        };
      });
    }

    if (wa.syllableAssessments) {
      this.currentSyllableAssessment = wa.syllableAssessments.map((sa) => {
        return {
          ...sa,
          cssClass:
            'sa-bg-' +
            CommonService.getSpeechAssessmentCssClass(sa.assessment.accuracy),
        };
      });
    }
  }
}
