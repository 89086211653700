<div style="padding: 20px">
  <h3>Speech assessment</h3>
  <p>
    Click on the
    <mat-icon fontSet="material-symbols-outlined">mic</mat-icon>
    button and read the script
    <span *ngIf="showRefreshButton">
      or click the
      <mat-icon fontSet="material-symbols-outlined">refresh</mat-icon>
      button to get a new one
    </span>
  </p>

  <div class="assessment-script ukata-simple-box">
    <nz-form-item>
      <nz-form-label>Enter your own script</nz-form-label>
      <input
        #scriptInput
        (blur)="showEditBox = false"
        [(ngModel)]="script"
        nz-input
      />
    </nz-form-item>
    <span *ngIf="!showEditBox">
      {{ script }}
      <ukata-text-to-speech
        [displayScript]="false"
        [script]="script"
      ></ukata-text-to-speech>
    </span>
    <mat-icon
      (click)="displayEditBox()"
      *ngIf="!showEditBox && showEditScriptIcon"
      class="edit-script-button"
      >edit
    </mat-icon>
  </div>
  <p></p>
  <button
    (click)="rotateScript()"
    *ngIf="showRefreshButton"
    nz-button
    nzType="primary"
  >
    <mat-icon fontSet="material-symbols-outlined">refresh</mat-icon>
    Refresh
  </button>
  <ukata-speech-assessment
    [script]="script"
    [showScript]="false"
    [showUserAudio]="hearMySpeech"
  ></ukata-speech-assessment>

  <div class="control-buttons">
    <label [(ngModel)]="hearMySpeech" nz-checkbox>Hear my speech</label>
  </div>
</div>
