import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'ukata-language-picker-multiple',
  templateUrl: './language-picker-multiple.component.html',
  styleUrls: ['./language-picker-multiple.component.scss'],
})
export class LanguagePickerMultipleComponent {
  allLanguages = CommonService.ItemLanguages.sort((a, b) => a.localeCompare(b));
  @Output()
  onUpdateLanguagesSelection = new EventEmitter<string[]>();
  @Input()
  selectedLanguages: string[] = [];
  protected readonly CommonService = CommonService;

  selectedChanges(data: string[]) {
    this.onUpdateLanguagesSelection.emit(data);
  }
}
