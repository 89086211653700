<p *ngIf="displayScript">{{ script }}</p>

<div class="d-flex flex-row">
  <button (click)="speak()" [disabled]="isLoading" color="accent" mat-mini-fab>
    <mat-icon fontSet="material-symbols-outlined">volume_up</mat-icon>
  </button>
  <div class="{{ playerClass }} " style="flex: 1">
    <audio controls id="player-{{ playerId }}"></audio>
  </div>
</div>

<ukata-loading-anim *ngIf="isLoading" [bigBars]="false"></ukata-loading-anim>
