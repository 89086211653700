import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ConversationPractice } from '../../../maker/interfaces/conversation-practice';
import { MediaItem } from '../../interfaces/media-item';
import { PublicMediaService } from '../../../core/services/public/public-media.service';

interface MediaDetails {
  mediaUrl: string;
  mimeType: string;
}

@Component({
  selector: 'ukata-conversation-view',
  templateUrl: './conversation-view.component.html',
  styleUrls: ['./conversation-view.component.scss'],
})
export class ConversationViewComponent implements AfterViewInit {
  mimeType: string = '';
  mediaUrl: string = '';

  @Input()
  showScriptToggle: boolean = true;

  @Input()
  showPlayerOnly: boolean = true; //in case of an actual test, we don't want to show the script

  @Input()
  showScript: boolean = false;

  //create map here to cache the media items
  mediaItemMap: Map<string, MediaDetails> = new Map<string, MediaDetails>();

  @Input()
  conversation: ConversationPractice | undefined;

  @ViewChild('audioMediaPlayer', { read: ElementRef })
  audioMediaPlayer!: ElementRef;
  isAudioLoading = false;
  currentPlayingIndex = 0;

  isPlayingConversation: boolean = false;

  constructor(private publicMediaService: PublicMediaService) {}

  ngAfterViewInit() {
    const player: HTMLAudioElement = this.audioMediaPlayer.nativeElement;

    player.oncanplay = () => {
      console.log('canplay');
      player.play();
    };

    player.onplay = () => {
      console.log('play');
      this.isAudioLoading = false;
    };
  }

  playAll() {
    if (!this.conversation) {
      console.log('no conversation');
      return;
    }
    this.isPlayingConversation = true;
    this.currentPlayingIndex = 0;

    this.previewMedia(
      this.conversation.conversation![this.currentPlayingIndex],
    ); //play the first one
    this.currentPlayingIndex++;

    const player = this.audioMediaPlayer.nativeElement;
    player.onended = () => {
      if (
        this.isPlayingConversation &&
        this.currentPlayingIndex < this.conversation!.conversation!.length
      ) {
        this.previewMedia(
          this.conversation!.conversation![this.currentPlayingIndex],
        );
        this.currentPlayingIndex++;
      } else {
        this.isPlayingConversation = false;
      }
    };
  }

  previewMedia(media: MediaItem) {
    if (this.mediaItemMap.has(media.id!)) {
      console.log('getting media from cache');
      const mediaDetails = this.mediaItemMap.get(media.id!)!;
      this.mediaUrl = mediaDetails.mediaUrl;
      this.mimeType = mediaDetails.mimeType;
      this.audioMediaPlayer.nativeElement.play();
      return;
    }
    console.log('fetching media from server');
    this.isAudioLoading = true;
    this.publicMediaService.fetchMediaUrl(media.id!).subscribe({
      next: (data) => {
        this.mediaUrl = data.url;
        this.mimeType = data.mimeType;
        this.mediaItemMap.set(media.id!, {
          mediaUrl: this.mediaUrl,
          mimeType: this.mimeType,
        });
      },
      error: (err) => {
        this.isAudioLoading = false;
        console.log(err);
      },
    });
  }

  stopPlayingAll() {
    this.isPlayingConversation = false;
    const audioPlayerNative = this.audioMediaPlayer
      .nativeElement as HTMLAudioElement;
    audioPlayerNative.pause();
  }
}
