import { Component, Input, OnInit } from '@angular/core';
import { PublicMediaService } from '../../../core/services/public/public-media.service';

@Component({
  selector: 'ukata-media-item-image-view',
  templateUrl: './media-item-image-view.component.html',
  styleUrls: ['./media-item-image-view.component.scss'],
})
export class MediaItemImageViewComponent implements OnInit {
  @Input()
  mediaUrl: string = '';

  @Input()
  mediaItemId: string = '';

  @Input()
  width: number | undefined;
  @Input()
  height: number | undefined;

  isImageLoading = false;

  constructor(private publicMediaService: PublicMediaService) {}

  ngOnInit(): void {
    if (this.mediaUrl)
      return;
    if (!this.mediaItemId || this.mediaItemId === '') {
      return;
    }
    this.isImageLoading = true;
    this.publicMediaService.fetchMediaUrl(this.mediaItemId).subscribe({
      next: (data) => {
        this.isImageLoading = false;
        this.mediaUrl = data.url;
      },
      error: (err) => {
        this.isImageLoading = false;
        console.log(err);
      },
    });
  }
}
