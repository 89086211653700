import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  override roles: any;

  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
  ) {
    super(router, keycloak);
  }

  isMaker() {
    return this.isRole('maker');
  }

  isAuthenticated() {
    return this.keycloak.getKeycloakInstance().authenticated;
  }

  getTokenString() {
    return this.keycloak.getKeycloakInstance().token;
  }

  isAdmin() {
    return this.isRole('admin');
  }

  accessTokenDetails() {
    return this.keycloak.getKeycloakInstance().tokenParsed;
  }

  userDetails() {
    if (this.isAuthenticated())
      return this.keycloak.getKeycloakInstance().idTokenParsed;
    return undefined;
  }

  logout() {
    this.keycloak.getKeycloakInstance().clearToken();
    this.keycloak.getKeycloakInstance().idTokenParsed = undefined;
    this.keycloak.logout(environment.SSO_REDIRECT).then((data) => {
      console.log('from logout', data);
    });
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    if (this.router.url === '/logout') {
      await this.keycloak.logout(window.location.origin);
      this.authenticated = false;
      return false;
    }
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }

  private isRole(role: string) {
    const tokenDetails = this.accessTokenDetails();
    return (
      tokenDetails &&
      tokenDetails['roles'] &&
      tokenDetails['roles'].includes(role)
    );
  }
}
