import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventDataCarrier } from '../../shared/interfaces/event-data-carrier';
import { environment } from '../../../environments/environment';
import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { AuthGuard } from './auth-guard.service';
import { NotificationService } from '../notifications/notification.service';

@Injectable({
  providedIn: 'root',
})
export class SseService {
  private static isConnected = false;
  private static isConnecting = false;
  private static retryCount = 0;
  private static readonly MAX_RETRIES = 5;
  private sseDataSubject: Subject<EventDataCarrier<any>> = new Subject<
    EventDataCarrier<any>
  >();
  private EVENTS_API = environment.BACKEND_SERVER + 'core/events';

  constructor(
    private authService: AuthGuard,
    private notificationService: NotificationService,
  ) {}

  subscribeToSSE(): Observable<EventDataCarrier<any>> {
    if (!this.authService.isAuthenticated()) {
      this.notificationService.info('Please login to use all features');
      return new Observable();
    }
    if (
      !SseService.isConnected &&
      SseService.retryCount < SseService.MAX_RETRIES
    ) {
      if (SseService.isConnecting) {
        console.log('already connecting...');
        return this.sseDataSubject.asObservable();
      }
      SseService.isConnecting = true;
      console.log('no subscription, start connecting...');
      SseService.retryCount++;
      this.connectToSSE();
    }
    return this.sseDataSubject.asObservable();
  }

  private connectToSSE() {
    const sse = this;
    fetchEventSource(this.EVENTS_API + `/subscribe`, {
      method: 'GET',
      headers: {
        Accept: EventStreamContentType,
        Authorization: `Bearer ` + this.authService.getTokenString(),
      },
      async onopen(response) {
        if (
          response.ok &&
          response.headers.get('content-type') === EventStreamContentType
        ) {
          SseService.isConnected = true;
          return; // everything's good
        } else if (
          response.status >= 400 &&
          response.status < 500 &&
          response.status !== 429
        ) {
          // client-side errors are usually non-retriable:
          throw new Error('Client error: ' + response.status);
        } else {
          //
        }
      },
      onmessage(msg) {
        // if the server emits an error message, throw an exception
        // so it gets handled by the onerror callback below:
        if (msg.event === 'Error') {
          throw new Error(msg.data);
        }
        const receivedData = JSON.parse(msg.data) as EventDataCarrier<any>;
        console.log('received data: ', receivedData);
        sse.sseDataSubject.next(receivedData);
      },
      onclose() {
        // if the server closes the connection unexpectedly, retry:
        throw new Error('Connection closed unexpectedly');
      },
      onerror(err) {
        console.log('error', err);
      },
    })
      .then((data) => {
        console.log('sse data', data);
      })
      .catch((err) => {
        console.error('sse error', err);
      });
  }
}
