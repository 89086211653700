<label class="file-upload-label" for="file-upload-input">
  <mat-icon color="primary">upload_file</mat-icon>
  <span>Select files to upload</span>
</label>
<ul class="ukata-ul-no-padding selected-file-list">
  <li *ngFor="let file of files | keyvalue" class="single-file">
    {{ file.value.name }}
    <mat-icon (click)="files.delete(file.key)" color="warn">delete</mat-icon>
  </li>
</ul>
<input
  (change)="onFileSelected($event)"
  [multiple]="multiple"
  class="file-input"
  id="file-upload-input"
  style="display: none"
  type="file"
/>
<ukata-loading-anim *ngIf="isUploading" [bigBars]="false"></ukata-loading-anim>
<button
  (click)="uploadFiles()"
  *ngIf="showUploadButton"
  [disabled]="isUploading || files.size === 0"
  nz-button
  nzType="primary"
>
  Upload
</button>
