<div *ngIf="isAudio; else videoPlayer" class="audio-preview">
  <audio controls id="player-{{ playerId }}">
    <source
      *ngIf="audioSource.src"
      src="{{ audioSource.src }}"
      type="{{ audioSource.type }}"
    />
  </audio>

  <ukata-loading-anim *ngIf="isLoading" [bigBars]="false"></ukata-loading-anim>
  <div *ngIf="!playerOnly">
    <mat-slide-toggle
      *ngIf="showScriptToggle"
      [(ngModel)]="showScript"
      nzType="primary"
    >
      Script
    </mat-slide-toggle>
    <p></p>
    <p *ngIf="showScript">{{ mediaItem?.transcript }}</p>

    <ukata-speech-assessment
      *ngIf="showSpeechAssessment && mediaItem?.transcript"
      [script]="mediaItem!.transcript"
      [showScript]="showScript"
    ></ukata-speech-assessment>
  </div>
</div>

<ng-template #videoPlayer>
  <video controls id="player-{{ playerId }}" playsinline>
    <source src="{{ mediaItem }}" type="video/mp4" />
    <!-- Captions are optional -->
    <track
      default
      kind="captions"
      label="English captions"
      src="/path/to/captions.vtt"
      srclang="en"
    />
  </video>
</ng-template>
