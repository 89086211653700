import { Component, Inject, Input, OnInit } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'ukata-plain-text-dialog',
  templateUrl: './plain-text-dialog.component.html',
  styleUrls: ['./plain-text-dialog.component.scss'],
})
export class PlainTextDialogComponent implements OnInit {
  @Input()
  label: string = 'Content';
  @Input()
  placeholder: string = '';

  constructor(
    @Inject(NZ_MODAL_DATA)
    public data: { content: string; label: string; placeholder: string },
  ) {}

  ngOnInit() {
    if (this.data.label) {
      this.label = this.data.label;
    }
    if (this.data.placeholder) {
      this.placeholder = this.data.placeholder;
    }
  }

  getContent() {
    return this.data.content;
  }
}
