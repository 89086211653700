import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { ConversationPractice } from '../../../maker/interfaces/conversation-practice';
import { ConversationPracticeService } from '../../../maker/services/conversation-practice.service';
import { MakerCommonService } from '../../../maker/services/maker-common.service';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
//@ts-ignore
import { v4 as uuid } from 'uuid';
import { LoadingService } from '../../../core/services/loading.service';
import { MediaItem } from '../../interfaces/media-item';
import { MakerMediaService } from '../../../core/services/maker-media.service';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'ukata-media-picker-dialog',
  templateUrl: './media-picker-dialog.component.html',
  styleUrls: ['./media-picker-dialog.component.scss'],
})
export class MediaPickerDialogComponent implements OnInit {
  mediaItemPageSize: number = 5;
  mediaItemPageIndex: number = 1;
  totalMediaItems: number = 0;
  mediaItems: MediaItem[] = [];
  mediaType: string = 'AUDIO';
  selectedMediaItem: MediaItem | undefined = undefined;
  searchTerm: string = '';
  includeIsChild: boolean = false;

  conversations: ConversationPractice[] = [];
  conversationPageSize: number = 5;
  conversationPageIndex: number = 1;
  totalConversationItems: number = 0;
  selectedConversation: ConversationPractice | undefined = undefined;
  returnData: {
    id: string;
    description?: string;
    title?: string;
    type?: string;
  } = { id: '' };
  @Input()
  includeConversation: boolean = true;

  @Input()
  includeMedia: boolean = true;
  protected readonly Math = Math;
  protected readonly MakerCommonService = MakerCommonService;
  protected readonly CommonService = CommonService;
  protected readonly undefined = undefined;

  constructor(
    public modal: NzModalRef,
    @Inject(NZ_MODAL_DATA)
    public data: {
      takeRawValue: boolean; //whether to return raw value (MediaItem, ConversationPractice) or lesson media
      includeConversation: boolean;
      includeMedia: boolean;
    },
    public loadingService: LoadingService,
    private conversationPracticeService: ConversationPracticeService,
    private makerMediaService: MakerMediaService,
    private cd: ChangeDetectorRef,
  ) {
    console.log('data', data);
    this.includeConversation = data.includeConversation;
    this.includeMedia = data.includeMedia;
  }

  ngOnInit(): void {
    if (this.includeConversation) this.fetchConversations();
    if (this.includeMedia) this.fetchMediaItems();
  }

  fetchConversations(pageIndex: number = 1, pageSize: number = 5) {
    const index = pageIndex - 1;
    this.conversationPracticeService
      .list('', 'en', [''], index, pageSize)
      .subscribe({
        next: (data) => {
          this.conversations = data.data;
          this.totalConversationItems = data.totalItems;
          this.cd.detectChanges();
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  selectConversation(data: any) {
    this.returnData = {
      id: data.id,
      title: data.title,
      type: 'CONVERSATION',
    };
  }

  onNoClick(): void {
    this.modal.close();
  }

  public fetchMediaItems(pageIndex: number = 1, pageSize: number = 5) {
    console.log('fetchMediaItems', this.mediaType);
    const index = pageIndex - 1;
    this.makerMediaService
      .list(
        index,
        pageSize,
        this.searchTerm,
        this.mediaType,
        this.includeIsChild,
      )
      .subscribe({
        next: (data) => {
          console.log('list data', data);
          this.mediaItems = data.data;
          this.totalMediaItems = data.totalItems;
        },
        error: (error) => {
          console.log('list error error', error);
        },
      });
  }

  selectMediaItem(mediaItem: MediaItem) {
    if (!mediaItem) {
      return;
    }
    this.returnData = this.data.takeRawValue
      ? mediaItem
      : {
          id: mediaItem.id,
          title: mediaItem.title,
          description: mediaItem.description,
          type: this.mediaType,
        };
  }

  fetchMedia() {
    this.fetchMediaItems(this.mediaItemPageIndex, this.mediaItemPageSize);
  }

  fetchMediaOnChange($event: string) {
    this.mediaType = $event;
    this.fetchMedia();
  }

  onConversationQueryParamsChange($event: NzTableQueryParams) {
    this.fetchConversations($event.pageIndex, $event.pageSize);
  }

  onMediaQueryParamsChange($event: NzTableQueryParams) {
    this.fetchMediaItems($event.pageIndex, $event.pageSize);
  }

  public getData() {
    return this.returnData;
  }
}
