import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

//@ts-ignore
import RecordRTC from 'recordrtc';

interface RecordedAudioOutput {
  requestId: string;
  blob: Blob;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class AudioRecordingService {
  private stream: MediaStream | undefined;
  private recorder: RecordRTC.MediaStreamRecorder;
  private _recorded = new Subject<RecordedAudioOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();
  private _isRecording = new Subject<boolean>();

  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  startRecording() {
    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((s) => {
        this.stream = s;
        this.record();
      })
      .catch((error) => {
        this._recordingFailed.next(error);
      });
  }

  abortRecording() {
    this.stopMedia();
  }

  stopRecording(requestId: string) {
    if (this.recorder) {
      this.recorder.stop(
        (blob: Blob) => {
          const fileName = encodeURIComponent(
            'audio_' + new Date().getTime() + '.wav',
          );
          this.stopMedia();
          this._recorded.next({
            requestId: requestId,
            blob: blob,
            title: fileName,
          });
        },
        () => {
          this.stopMedia();
          this._recordingFailed.next('Error occurred while recording audio');
        },
      );
    }
  }

  private record() {
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      sampleRate: 48000,
      mimeType: 'audio/wav',
    });

    this.recorder.record();
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach((track) => track.stop());
        this.stream = undefined;
      }
    }
  }
}
