<svg
  *ngIf="bigBars; else smallLoader"
  height="90px"
  preserveAspectRatio="xMidYMid"
  style="margin: auto; background: rgba(241, 242, 243, 0); display: block"
  viewBox="0 0 100 100"
  width="200px"
  xmlns="http://www.w3.org/2000/svg"
>
  <g transform="rotate(180 50 50)">
    <rect
      fill="#93dbe9"
      height="40"
      width="10"
      x="6.111111111111111"
      y="43.625"
    >
      <animate
        attributeName="height"
        begin="-0.75s"
        calcMode="spline"
        dur="1s"
        keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;0.33;0.66;1"
        values="8.5;12.75;1.7000000000000002;8.5"
      ></animate>
    </rect>
    <rect
      fill="#689cc5"
      height="40"
      width="10"
      x="17.22222222222222"
      y="43.625"
    >
      <animate
        attributeName="height"
        begin="0s"
        calcMode="spline"
        dur="1s"
        keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;0.33;0.66;1"
        values="8.5;12.75;1.7000000000000002;8.5"
      ></animate>
    </rect>
    <rect
      fill="#5e6fa3"
      height="40"
      width="10"
      x="28.333333333333336"
      y="43.625"
    >
      <animate
        attributeName="height"
        begin="-0.875s"
        calcMode="spline"
        dur="1s"
        keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;0.33;0.66;1"
        values="8.5;12.75;1.7000000000000002;8.5"
      ></animate>
    </rect>
    <rect
      fill="#3b4368"
      height="40"
      width="10"
      x="39.44444444444444"
      y="43.625"
    >
      <animate
        attributeName="height"
        begin="-0.25s"
        calcMode="spline"
        dur="1s"
        keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;0.33;0.66;1"
        values="8.5;12.75;1.7000000000000002;8.5"
      ></animate>
    </rect>
    <rect
      fill="#191d3a"
      height="40"
      width="10"
      x="50.55555555555556"
      y="43.625"
    >
      <animate
        attributeName="height"
        begin="-0.5s"
        calcMode="spline"
        dur="1s"
        keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;0.33;0.66;1"
        values="8.5;12.75;1.7000000000000002;8.5"
      ></animate>
    </rect>
    <rect
      fill="#d9dbee"
      height="40"
      width="10"
      x="61.66666666666667"
      y="43.625"
    >
      <animate
        attributeName="height"
        begin="-0.625s"
        calcMode="spline"
        dur="1s"
        keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;0.33;0.66;1"
        values="8.5;12.75;1.7000000000000002;8.5"
      ></animate>
    </rect>
    <rect
      fill="#b3b7e2"
      height="40"
      width="10"
      x="72.77777777777777"
      y="43.625"
    >
      <animate
        attributeName="height"
        begin="-0.375s"
        calcMode="spline"
        dur="1s"
        keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;0.33;0.66;1"
        values="8.5;12.75;1.7000000000000002;8.5"
      ></animate>
    </rect>
    <rect
      fill="#93dbe9"
      height="40"
      width="10"
      x="83.88888888888889"
      y="43.625"
    >
      <animate
        attributeName="height"
        begin="-0.125s"
        calcMode="spline"
        dur="1s"
        keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;0.33;0.66;1"
        values="8.5;12.75;1.7000000000000002;8.5"
      ></animate>
    </rect>
  </g>
</svg>

<ng-template #smallLoader>
  <svg
    height="20px"
    preserveAspectRatio="xMidYMid"
    style="
      margin: auto;
      background: none;
      display: block;
      shape-rendering: auto;
    "
    viewBox="0 0 100 100"
    width="20px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="84" cy="50" fill="#e15b64" r="10">
      <animate
        attributeName="r"
        begin="0s"
        calcMode="spline"
        dur="0.25s"
        keySplines="0 0.5 0.5 1"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="10;0"
      ></animate>
      <animate
        attributeName="fill"
        begin="0s"
        calcMode="discrete"
        dur="1s"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="#e15b64;#abbd81;#f8b26a;#f47e60;#e15b64"
      ></animate>
    </circle>
    <circle cx="16" cy="50" fill="#e15b64" r="10">
      <animate
        attributeName="r"
        begin="0s"
        calcMode="spline"
        dur="1s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="0;0;10;10;10"
      ></animate>
      <animate
        attributeName="cx"
        begin="0s"
        calcMode="spline"
        dur="1s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="16;16;16;50;84"
      ></animate>
    </circle>
    <circle cx="50" cy="50" fill="#f47e60" r="10">
      <animate
        attributeName="r"
        begin="-0.25s"
        calcMode="spline"
        dur="1s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="0;0;10;10;10"
      ></animate>
      <animate
        attributeName="cx"
        begin="-0.25s"
        calcMode="spline"
        dur="1s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="16;16;16;50;84"
      ></animate>
    </circle>
    <circle cx="84" cy="50" fill="#f8b26a" r="10">
      <animate
        attributeName="r"
        begin="-0.5s"
        calcMode="spline"
        dur="1s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="0;0;10;10;10"
      ></animate>
      <animate
        attributeName="cx"
        begin="-0.5s"
        calcMode="spline"
        dur="1s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="16;16;16;50;84"
      ></animate>
    </circle>
    <circle cx="16" cy="50" fill="#abbd81" r="10">
      <animate
        attributeName="r"
        begin="-0.75s"
        calcMode="spline"
        dur="1s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="0;0;10;10;10"
      ></animate>
      <animate
        attributeName="cx"
        begin="-0.75s"
        calcMode="spline"
        dur="1s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="16;16;16;50;84"
      ></animate>
    </circle>
  </svg>
</ng-template>
