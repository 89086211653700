import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { NotificationService } from '../notifications/notification.service';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(
    private notificationsService: NotificationService,
    private route: Router,
  ) {
    super();
  }

  override handleError(error: Error | HttpErrorResponse) {
    let errorMessage;
    if (!(error instanceof HttpErrorResponse)) {
      console.log('not handling non-http error', error);
      return;
    }
    if (error.status === 0) {
      console.log('unknown error');
      return;
    }
    errorMessage = error?.error?.message || error?.message;
    if ([401].indexOf(error.status) !== -1) {
      this.route
        .navigateByUrl('/user/dashboard')
        .then((r) => console.log('redirect -> login', r));
      return;
    }

    if (!errorMessage || typeof errorMessage !== 'string') {
      console.log(
        'error has no message or message is not a string',
        errorMessage,
      );
      return;
    }
    this.notificationsService.error(errorMessage);
  }
}
