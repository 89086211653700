import { NgModule, Optional, SkipSelf } from '@angular/core';
import { DataDeletionComponent } from '../public/components/data-deletion/data-deletion.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterModule } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { QuillEditorComponent } from 'ngx-quill';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { TermsComponent } from './components/terms/terms.component';
import { LoadingAnimComponent } from './components/loading-anim/loading-anim.component';
import { SymbolComponent } from './components/symbol/symbol.component';
import { ConversationPreviewComponent } from './components/conversation-preview-dialog/conversation-preview.component';
import { ConversationViewComponent } from './components/conversation-view/conversation-view.component';
import { MediaItemViewComponent } from './components/media-item-view/media-item-view.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { VoiceRecorderComponent } from './components/speech/voice-recorder/voice-recorder.component';
import { MediaPreviewComponent } from './components/media-preview/media-preview.component';
import { MediaTypePickerComponent } from './components/media-type-picker/media-type-picker.component';
import { SpeechAssessmentComponent } from './components/speech/mini-speech-assessment/speech-assessment.component';
import { MediaItemImageViewComponent } from './components/media-item-image-view/media-item-image-view.component';
import { TextToSpeechComponent } from './components/speech/text-to-speech/text-to-speech.component';
import { ConversationViewIdOnlyComponent } from './components/conversation-view-id-only/conversation-view-id-only.component';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { LevelPickerComponent } from './components/level-picker/level-picker.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PlainTextDialogComponent } from './components/plain-text-dialog/plain-text-dialog.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { LoginInvitationComponent } from './components/login-invitation/login-invitation.component';
import { VisibilityPickerComponent } from './components/visibility-picker/visibility-picker.component';
import { VocabularyListItemsViewComponent } from './components/vocabulary-list-items-view/vocabulary-list-items-view.component';
import { IpaToSpeechComponent } from './components/speech/ipa-to-speech/ipa-to-speech.component';
import { IpaDisplayComponent } from './components/speech/ipa-display/ipa-display.component';
import { LanguagePickerMultipleComponent } from './components/language-picker-multiple/language-picker-multiple.component';
import { LoadingComponent } from './components/loading/loading.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    CountdownComponent,
    UserProfileComponent,
    TermsComponent,
    DataDeletionComponent,
    LoadingAnimComponent,
    SymbolComponent,
    ConversationPreviewComponent,
    ConversationViewComponent,
    MediaItemViewComponent,
    HtmlEditorComponent,
    VoiceRecorderComponent,
    MediaPreviewComponent,
    MediaTypePickerComponent,
    SpeechAssessmentComponent,
    MediaItemImageViewComponent,
    TextToSpeechComponent,
    ConversationViewIdOnlyComponent,
    LanguagePickerComponent,
    LevelPickerComponent,
    BreadcrumbComponent,
    PlainTextDialogComponent,
    VideoPlayerComponent,
    LoginInvitationComponent,
    VisibilityPickerComponent,
    VocabularyListItemsViewComponent,
    IpaToSpeechComponent,
    IpaDisplayComponent,
    LanguagePickerMultipleComponent,
  ],
  exports: [
    ConfirmDialogComponent,
    // material
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonModule,
    LoadingComponent,
    CountdownComponent,
    LoadingAnimComponent,
    SymbolComponent,
    ConversationPreviewComponent,
    ConversationViewComponent,
    MediaItemViewComponent,
    HtmlEditorComponent,
    MediaPreviewComponent,
    MediaTypePickerComponent,
    MediaItemImageViewComponent,
    TextToSpeechComponent,
    ConversationViewIdOnlyComponent,
    SpeechAssessmentComponent,
    VoiceRecorderComponent,
    LanguagePickerComponent,
    LevelPickerComponent,
    BreadcrumbComponent,
    VideoPlayerComponent,
    LoginInvitationComponent,
    VisibilityPickerComponent,
    VocabularyListItemsViewComponent,
    IpaToSpeechComponent,
    IpaDisplayComponent,
    LanguagePickerMultipleComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    CdkAccordionModule,
    MatSliderModule,
    MatTabsModule,
    MatExpansionModule,
    MatBadgeModule,
    MatSlideToggleModule,
    NzIconModule,
    MatIconModule,
    NzModalModule,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    NzIconModule,
    NgOptimizedImage,
    TranslateModule,
    RouterLink,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatChipsModule,
    NzProgressModule,
    NzSpinModule,
    NzInputModule,
    NzFormModule,
    NzGridModule,
    NzSelectModule,
    NzCardModule,
    NzRadioModule,
    NzButtonModule,
    NzTableModule,
    NzCheckboxModule,
    NzPaginationModule,
    QuillEditorComponent,
    NzBreadCrumbModule,
    LoadingComponent,
  ],
  providers: [],
})
export class SharedModule {}
