<div *ngIf="report; else loading">
  <p>Click on the words to see their syllables</p>
  <div *ngIf="report.wordAssessments && report.wordAssessments.length > 0">
    <!--    Detailed report -->
    <div>
      <span
        (click)="loadDetailedReportForWord(wa)"
        *ngFor="let wa of wordsAssessmentWithCSS"
        class="single-word {{ wa.cssClass }}"
        >{{ wa.word }} &nbsp;</span
      >
    </div>

    <div *ngIf="currentWordAssessment">
      <!--        <h4>Phoneme</h4>-->
      <!--        <ng-container *ngTemplateOutlet="singleWordReport"></ng-container>-->
      <h4>Syllables</h4>
      <ng-container *ngTemplateOutlet="singleWordSyllableReport"></ng-container>
    </div>
  </div>
</div>

<ng-template #loading>
  <ukata-loading-anim></ukata-loading-anim>
</ng-template>

<ng-template #singleWordReport>
  <div>
    <div
      *ngFor="let phone of currentPhonemeAssessment"
      class="single-phoneme-assessment"
    >
      <span class="phoneme">{{ phone.phoneme }} </span>
      <span class="assessment-score">{{ phone.assessment.accuracy }}</span>
    </div>
  </div>
</ng-template>
<ng-template #singleWordSyllableReport>
  <div>
    <div
      *ngFor="let syllable of currentSyllableAssessment"
      class="single-phoneme-assessment {{ syllable.cssClass }}"
    >
      <div>
        <span class="phoneme">{{ syllable.syllable }} </span>
        <span class="assessment-score">{{ syllable.assessment.accuracy }}</span>
      </div>
      <div class="syllable-speech">
        <ukata-ipa-to-speech [script]="syllable.syllable"></ukata-ipa-to-speech>
      </div>
    </div>
  </div>
</ng-template>
