import { Component, Input } from '@angular/core';
import { SingleIpa } from '../../../../shared/interfaces/single-ipa';

@Component({
  selector: 'ukata-ipa-display',
  templateUrl: './ipa-display.component.html',
  styleUrls: ['./ipa-display.component.scss'],
})
export class IpaDisplayComponent {
  @Input()
  ipa: Map<string, SingleIpa[]> | undefined;
}
