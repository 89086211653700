import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { AsyncPipe, NgIf } from '@angular/common';
import { LoadingService } from '../../../core/services/loading.service';

@Component({
  selector: 'ukata-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzSpinModule, AsyncPipe, NgIf],
  standalone: true,
})
export class LoadingComponent implements OnInit {
  @Input()
  detectRouterLoading: boolean = false;

  constructor(
    public loadingService: LoadingService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationStart ||
        event instanceof RouteConfigLoadStart
      ) {
        this.loadingService.on();
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationError ||
        event instanceof NavigationCancel ||
        event instanceof RouteConfigLoadEnd
      ) {
        this.loadingService.off();
      }
    });
  }
}
