import { Component, Input, SimpleChange } from '@angular/core';
import { ConversationPractice } from '../../../maker/interfaces/conversation-practice';
import { LoadingService } from '../../../core/services/loading.service';
import { PublicMediaService } from '../../../core/services/public/public-media.service';

@Component({
  selector: 'ukata-conversation-view-id-only',
  templateUrl: './conversation-view-id-only.component.html',
  styleUrls: ['./conversation-view-id-only.component.scss'],
})
export class ConversationViewIdOnlyComponent {
  @Input()
  conversationId: string = '';

  @Input()
  showPlayerOnly: boolean = false;

  conversation: ConversationPractice | undefined;

  constructor(
    private publicMediaService: PublicMediaService,
    public loadingService: LoadingService,
  ) {}

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let change: SimpleChange = changes['conversationId'];
    if (change && change.currentValue)
      this.conversationId = change.currentValue;

    console.log('conversation id', this.conversationId);
    this.publicMediaService
      .getConversationPractice(this.conversationId)
      .subscribe((conversation) => {
        this.conversation = conversation;
      });
  }
}
