import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { MediaItem } from '../../shared/interfaces/media-item';
import { SingleValueResponse } from '../../shared/interfaces/single-value-response';
import { ConversationPractice } from '../../maker/interfaces/conversation-practice';
import { MediaResponse } from '../../shared/interfaces/media-response';

@Injectable({
  providedIn: 'root',
})
export class LearnerMediaService {
  private TAKER_MEDIA_URL = environment.LEARNER_API + 'media';

  constructor(private http: HttpClient) {}

  public upload(formData: FormData): Observable<MediaItem> {
    return this.http.post<MediaItem>(
      this.TAKER_MEDIA_URL + '/upload',
      formData,
    );
  }

  public speechToText(formData: FormData): Observable<SingleValueResponse> {
    return this.http.post<SingleValueResponse>(
      this.TAKER_MEDIA_URL + '/speech-to-text',
      formData,
    );
  }

  public speechAssessment(formData: FormData): Observable<SingleValueResponse> {
    return this.http.post<SingleValueResponse>(
      this.TAKER_MEDIA_URL + '/speech-assessment',
      formData,
    );
  }

  public fetchMediaUrl(mediaId: string): Observable<MediaResponse> {
    return this.http
      .get<MediaResponse>(this.TAKER_MEDIA_URL + `/preview/${mediaId}`)
      .pipe(shareReplay());
  }

  public fetchMediaUrls(mediaIds: string[]): Observable<MediaResponse[]> {
    return this.http
      .get<
        MediaResponse[]
      >(this.TAKER_MEDIA_URL + `/preview/list/${mediaIds.join(',')}`)
      .pipe(shareReplay());
  }

  public getSingleMediaItem(mediaId: string): Observable<MediaItem> {
    return this.http
      .get<MediaItem>(this.TAKER_MEDIA_URL + `/get-media/${mediaId}`)
      .pipe(shareReplay());
  }

  public getConversationPractice(id: string): Observable<ConversationPractice> {
    return this.http
      .get<ConversationPractice>(
        this.TAKER_MEDIA_URL + `/conversation-practice/${id}`,
      )
      .pipe(shareReplay());
  }
}
