const packageJson = require('../../package.json');
const backendServer =
  window.location.host === 'vi.ukata.io'
    ? 'https://vi-api.ukata.io/v1/core/'
    : 'https://api.ukata.io/v1/core/';
export const environment = {
  appName: 'Ukata',
  envName: 'PROD',
  production: true,
  BACKEND_SERVER: backendServer,
  LEARNER_API: backendServer + 'taker/',
  MAKER_API: backendServer + 'maker/',
  ADMIN_API: backendServer + 'admin/',
  PUBLIC_API: backendServer + 'public/',
  SSO_SERVER: 'https://sso.ukata.io/',
  SSO_REDIRECT: 'https://ukata.io/',
  APP_HOME: 'https://ukata.io/',
  SSO_REALM: 'ukata',
  SSO_CLIENT: 'frontend-client',
  test: false,
  i18nPrefix: '',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress'],
    eslint: packageJson.devDependencies['eslint'],
  },

  enableServiceWorker: false,
};
