<ukata-loading-anim
  *ngIf="isImageLoading; else imageView"
  [bigBars]="false"
></ukata-loading-anim>
<ng-template #imageView>
  <img
    *ngIf="width && height; else noWidthHeight"
    width="{{ width }}"
    height="{{ height }}"
    [src]="mediaUrl"
  />
</ng-template>

<ng-template #noWidthHeight>
  <img [src]="mediaUrl" />
</ng-template>
