import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { LearnerMediaService } from '../../../../learner/services/learner-media.service';
import { AudioRecordingService } from '../../../../learner/services/audio-recording.service';
//@ts-ignore
import Plyr from 'plyr';
import { SpeechAssessmentReport } from '../../../../shared/interfaces/assessment-result';
import { NotificationService } from '../../../../core/notifications/notification.service';
import { SseService } from '../../../../core/services/sse.service';
import { CommonService } from '../../../../core/services/common.service';

@Component({
  selector: 'ukata-voice-recorder',
  templateUrl: './voice-recorder.component.html',
  styleUrls: ['./voice-recorder.component.scss'],
})
export class VoiceRecorderComponent implements OnInit, AfterViewInit {
  @Input() script: string = '';
  @Input() postProcessing: string = '';
  @Input() showUserSpeechPlayer: boolean = true;
  @Input() displayTTSAnimation: boolean = true;

  @Input() parentRequestId: string = '';
  userSpeak = '';
  blob: Blob | undefined;
  audioUrl: string | undefined;
  isRecording: boolean = false;
  isDoingPostProcessing: boolean = false;
  @ViewChild('audioElement') audioElement:
    | ElementRef<HTMLAudioElement>
    | undefined;
  showAudioLoading = false;
  playerId: string = CommonService.generateRandomString();

  @Output()
  private onSpeechToTextResult: EventEmitter<{
    parentRequestId: string;
    result: string;
  }> = new EventEmitter();
  @Output()
  private onSpeechAssessmentResult: EventEmitter<{
    parentRequestId: string;
    report: SpeechAssessmentReport;
  }> = new EventEmitter();
  @Output()
  private onRecordingEventChange: EventEmitter<{
    parentRequestId: string;
    event: string;
  }> = new EventEmitter(); //to send events on start, stop

  @Output()
  private onSpeechPostProcessingEventChange: EventEmitter<string> =
    new EventEmitter();
  private player: Plyr | undefined;

  private speechAssessmentReportId: string = '';

  constructor(
    private learnerMediaService: LearnerMediaService,
    private audioRecordingService: AudioRecordingService,
    private sseService: SseService,
    private notificationService: NotificationService,
  ) {
    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      if (data.requestId !== this.parentRequestId) {
        console.log(
          'request id mismatch, ignoring recorded blob',
          data.requestId,
          this.parentRequestId,
        );
        return;
      }
      this.blob = data.blob;
      this.audioUrl = window.URL.createObjectURL(this.blob);
      this.setAudioPlayerSource(this.audioUrl, 'audio/wav');

      if (this.postProcessing === 'speech_to_text') {
        this.uploadForSpeechToText();
      } else if (this.postProcessing === 'speech_analysis') {
        this.uploadForSpeechAssessment();
      }
    });
  }

  ngAfterViewInit() {
    this.sseService.subscribeToSSE().subscribe({
      next: (event) => {
        if (event.topic.toLowerCase() === 'speech_assessment') {
          const report = event.data as SpeechAssessmentReport;
          if (report.reportId !== this.speechAssessmentReportId) {
            console.log(
              'report id mismatch, ignoring report',
              report.reportId,
              this.speechAssessmentReportId,
            );
            return;
          }
          this.isDoingPostProcessing = false;

          if (event.status.toLowerCase() !== 'success') {
            this.notificationService.info(
              'Something went wrong, please try again',
            );
            return;
          }

          this.onSpeechPostProcessingEventChange.emit('done');
          this.onSpeechAssessmentResult.emit({
            report,
            parentRequestId: this.parentRequestId,
          });
        }
      },
      error: (err) => {
        console.log(err);
        this.isDoingPostProcessing = false;
      },
    });
    this.player = CommonService.createPlyrAudioPlayer(
      `#player-${this.playerId}`,
    );
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let change: SimpleChange = changes['showUserSpeechPlayer'];
    if (change && change.currentValue) {
      setTimeout(() => {
        this.player = CommonService.createPlyrAudioPlayer(
          `#player-${this.playerId}`,
        );
        if (this.audioUrl)
          this.setAudioPlayerSource(this.audioUrl!, 'audio/wav');
      }, 100);
    }
  }

  ngOnInit(): void {}

  stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording(this.parentRequestId);
      this.onRecordingEventChange.emit({
        parentRequestId: this.parentRequestId,
        event: 'stopped',
      });
      this.isRecording = false;
    }
  }

  startRecording() {
    console.log('start recording with parent request id', this.parentRequestId);
    this.isRecording = true;
    this.onRecordingEventChange.emit({
      parentRequestId: this.parentRequestId,
      event: 'started',
    });
    this.audioRecordingService.startRecording();
  }

  private setAudioPlayerSource(sourceUrl: string, mime: string) {
    this.player.source = {
      type: 'audio',
      sources: [
        {
          src: sourceUrl,
          type: mime,
        },
      ],
    };
  }

  private uploadForSpeechAssessment() {
    this.isDoingPostProcessing = true;
    let formData = new FormData();
    formData.set('script', this.script);
    formData.set('file', new File([this.blob!], 'user_audio.wav'));
    this.onSpeechPostProcessingEventChange.emit('started');
    this.learnerMediaService.speechAssessment(formData).subscribe({
      next: (response) => {
        console.log(
          'speech assessment request summited, report id: ',
          response.value,
        );
        this.speechAssessmentReportId = response.value;
      },
      error: (error) => {
        this.isDoingPostProcessing = false;
        console.log(error);
      },
    });
  }

  private uploadForSpeechToText() {
    this.isDoingPostProcessing = true;
    let formData = new FormData();
    formData.set('script', this.script);
    formData.set('file', new File([this.blob!], 'user_audio.wav'));
    this.onSpeechPostProcessingEventChange.emit('started');
    this.learnerMediaService.speechToText(formData).subscribe({
      next: (response) => {
        console.log(response);
        this.onSpeechToTextResult.emit({
          parentRequestId: this.parentRequestId,
          result: response.value,
        });
        this.onSpeechPostProcessingEventChange.emit('done');
        this.userSpeak = response.value.trim();
        this.isDoingPostProcessing = false;
      },
      error: (error) => {
        console.log(error);
        this.isDoingPostProcessing = false;
      },
    });
  }
}
