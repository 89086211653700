{
  "name": "kata-ui",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host=0.0.0.0 --disable-host-check --configuration development",
    "dev": "ng serve --host=0.0.0.0 --disable-host-check --configuration development",
    "serve": "ng serve --host=0.0.0.0 --disable-host-check --configuration development",
    "build": "ng build",
    "build:prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.2",
    "@angular/cdk": "^18.0.2",
    "@angular/common": "^18.0.2",
    "@angular/compiler": "^18.0.2",
    "@angular/core": "^18.0.2",
    "@angular/forms": "^18.0.2",
    "@angular/material": "^18.0.2",
    "@angular/platform-browser": "^18.0.2",
    "@angular/platform-browser-dynamic": "^18.0.2",
    "@angular/router": "^18.0.2",
    "@ant-design/icons-angular": "^18.0.0",
    "@ctrl/tinycolor": "^4.1.0",
    "@microsoft/fetch-event-source": "^2.0.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@types/node": "^20.12.7",
    "@types/showdown": "^2.0.6",
    "chart.js": "^4.4.2",
    "keycloak-angular": "~16.0.1",
    "keycloak-js": "^25.0.0",
    "marked": "^12.0.0",
    "monaco-editor": "^0.50.0",
    "ng-zorro-antd": "18.2.1",
    "ngx-markdown": "^18.0.0",
    "ngx-monaco-editor-v2": "^18.1.0",
    "ngx-quill": "^26.0.4",
    "plyr": "^3.7.8",
    "prismjs": "^1.29.0",
    "quill": "^2.0.0",
    "recordrtc": "^5.6.2",
    "rxjs": "~7.5.7",
    "showdown": "^2.1.0",
    "tslib": "^2.6.2",
    "uuid": "^9.0.1",
    "zone.js": "~0.14.5"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.3",
    "@angular/cli": "~18.0.3",
    "@angular/compiler-cli": "^18.0.2",
    "@fullhuman/postcss-purgecss": "^6.0.0",
    "@types/jasmine": "~4.3.6",
    "jasmine-core": "~4.5.0",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.1.1",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "typescript": "~5.4.5"
  }
}
