<nz-form-item>
  <nz-form-label>Level</nz-form-label>
  <nz-form-control>
    <nz-select
      (ngModelChange)="onLevelChange.emit($event)"
      [(ngModel)]="level"
      [ngModelOptions]="{ standalone: true }"
    >
      <nz-option
        *ngFor="let level of CommonService.MakerItemLevels"
        [nzLabel]="level"
        [nzValue]="level"
      >
        {{ level }}
      </nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>
